import React from 'react'
import { Box, Link, Typography } from '@mui/material'

import { ReactComponent as BookMarkIcon } from 'assets/bookmark-icon.svg'

const MaterialItem = ({ material }) => (
  <Box
    sx={{
      width: '80%',
      p: 6,
      backgroundColor: '#F4E8BD',
      borderRadius: 4,
      position: 'initial',
    }}>
    <BookMarkIcon />
    <Typography fontWeight="bold" fontFamily="inter">
      {material.name}
    </Typography>
    <Link
      href={material.link}
      target="_blank"
      rel="noopener noreferrer"
      sx={{ textDecoration: 'none', color: 'inherit' }}>
      <Typography
        fontFamily="inter"
        sx={{ mt: 2, fontSize: 12, textDecoration: 'underline' }}>
        Click here to view the material
      </Typography>
    </Link>
  </Box>
)

export default MaterialItem
