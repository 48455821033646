import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { Box, Typography } from '@mui/material'

import api from 'lib/api'
import { setSnackbar } from 'reducers/ui'
import { useAuth0 } from '@auth0/auth0-react'

const RolePage = (props) => {
  const [roles, setRoles] = useState([])
  const { getAccessTokenSilently, user } = useAuth0()

  useEffect(() => {
    const fetchRoles = async () => {
      try {
        const token = await getAccessTokenSilently()
        const response = await api.role.list({}, token)
        setRoles(response.data.roles)
      } catch (error) {
        props.setSnackbar({
          open: true,
          message: `Error fetching roles: ${error.message}`,
          severity: 'error',
        })
      }
    }

    fetchRoles()
    // eslint-disable-next-line
  }, [])

  return (
    <Box>
      <Typography variant="h4" textAlign="center" mb={2}>
        Roles
      </Typography>
      <Box
        sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        {roles &&
          roles.map((role, index) => (
            <Box
              key={index}
              sx={{ display: 'flex', flexDirection: 'column', maxWidth: 400 }}>
              <Typography variant="h5" fontWeight="bold" mb={2}>
                {role.role.id}: {role.role.name}
              </Typography>
              <Typography variant="body1" fontStyle="italic" mb={2}>
                {role.role.description}
              </Typography>
              <Box display="flex" gap={2} px={2} overflow="scroll">
                {role.permissions.map((permission) => (
                  <Box key={permission.id} minWidth={200}>
                    <Typography
                      fontSize={8}
                      textAlign="center"
                      fontWeight="bold">
                      PERMISSION ID:
                    </Typography>
                    <Typography textAlign="center" fontWeight="bold" mb={2}>
                      {permission.id}
                    </Typography>
                    <Typography fontSize={10} textAlign="center" mb={2}>
                      {permission.name}
                    </Typography>
                    <Typography
                      fontSize={10}
                      textAlign="center"
                      fontStyle="italic"
                      mb={2}>
                      {permission.description}
                    </Typography>
                  </Box>
                ))}
              </Box>
            </Box>
          ))}
      </Box>
    </Box>
  )
}

const mapDispatchToProps = {
  setSnackbar,
}

export default connect(null, mapDispatchToProps)(RolePage)
