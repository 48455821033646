import { createTheme } from '@mui/material/styles'

const theme = createTheme({
  palette: {
    card: {
      main: '#F7ECC5',
      mainHover: '#F3E2A5',
    },
    primary: {
      main: '#0959F3',
      light: '#0959F3',
      dark: '#0345C4',
      contrastText: '#FFF',
      weak: '#DBEBFF',
      weakHover: '#C2DDFF',
    },
    secondary: {
      main: '#001912',
      light: '#9FB8D7',
      dark: '#000000',
      weak: '#EFF4FB',
    },
    graph: {
      absent: '#FF0000',
      completed: '#0959F3',
      incomplete: '#798CA3',
      excited: '#14E303',
      engaged: '#0D9C1C',
      curious: '#DE52EB',
      confused: '#DC0029',
      frustrated: '#76120C',
      bored: '#06296C',
      transparent: '#00000000',
    },
    background: {
      default: '#EFF3F8',
      sideNav: '#D8D8D8',
      weak: '#00000011',
    },
    inputs: {
      main: '#798CA3',
      light: '#FFFFFF',
      weak: '#D8D8D8',
      dark: '#E0E0E0',
    },
    messages: {
      sent: '#FFFFFF',
      received: '#91BBF04D',
    },
  },
  components: {
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          borderRadius: '8px',
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: '8px',
          paddingLeft: '12px',
          paddingRight: '12px',
          boxShadow: 'none',
          textTransform: 'none',
        },
        contained: {
          paddingLeft: '24px',
          paddingRight: '24px',
        },
      },
    },
    MuiAppBar: {
      styleOverrides: {
        root: {
          boxShadow: 'none',
        },
      },
    },
  },
  typography: {
    fontFamily: ['Besley', 'Inter', 'serif'].join(','),
    fontSize: 14,
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightMedium: 500,
  },
})

export default theme
