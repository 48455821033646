import React from 'react';
import { Box, Typography } from '@mui/material';

const CustomButton = ({ onClick, children }) => (
  <div
    onClick={onClick}
    style={{
      display: 'inline-block',
      padding: '8px 14px',
      borderRadius: '8px',
      fontFamily: '"Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif',
      fontSize: '0.875rem',
      fontWeight: 500,
      lineHeight: 1.75,
      color: 'rgba(0, 0, 0, 0.87)',
      backgroundColor: '#fff',
      boxShadow: '0px 10px 20px 0px rgba(159, 184, 215, 0.24)',
      cursor: 'pointer',
      position: 'relative',
      zIndex: 2,
      border: '2px solid transparent',
      outline: 'none',
      userSelect: 'none',
      transition: 'border-color 0.3s ease',
    }}
    onMouseEnter={(e) => e.currentTarget.style.borderColor = 'rgba(159, 184, 215, 0.5)'}
    onMouseLeave={(e) => e.currentTarget.style.borderColor = 'transparent'}
  >
    {children}
    <div
      style={{
        content: '""',
        position: 'absolute',
        backgroundColor: 'rgba(159, 184, 215, 0.3)',
        opacity: 0.3,
        width: '100%',
        height: '40%',
        bottom: -5,
        left: 0,
        borderRadius: '8px',
        boxShadow: '0px 0px 20px 20px rgba(159, 184, 215, 0.24)',
        zIndex: -1,
      }}
    />
  </div>
);

const RecommendedChatOptions = ({ onSelectOption }) => {
  const options = [
    "Hi Ellie! What are we learning today?",
    "Hello! How should we get started?",
    "Nice to meet you, Ellie!",
  ];

  return (
    <Box sx={{ position: 'relative', zIndex: 2 }}>
      <Typography 
        variant="body2" 
        sx={{ 
          mb: 1.5, 
          fontWeight: 500,
          fontFamily: '"Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif',
          fontSize: '0.9375rem',
          lineHeight: 1.5,
          color: 'primary.main',
        }}
      >
        Get started with these options:
      </Typography>
      <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1.5 }}>
        {options.map((option, index) => (
          <CustomButton key={index} onClick={() => onSelectOption(option)}>
            {option}
          </CustomButton>
        ))}
      </Box>
    </Box>
  );
};

export default RecommendedChatOptions;