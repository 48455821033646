import React, { useState } from 'react'
import { connect } from 'react-redux'
import { useHistory } from 'react-router-dom/cjs/react-router-dom'
import { TextField, Box, Button } from '@mui/material'
import { LoadingButton } from '@mui/lab'

import api from 'lib/api'
import AuthLayout from 'layouts/Auth'
import { setUser } from 'reducers/user'
import { setSnackbar } from 'reducers/ui'

const LoginPage = (props) => {
  const history = useHistory()

  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [loading, setLoading] = useState(false)

  const handleLogin = async () => {
    setLoading(true)
    try {
      const response = await api.user.auth.login({ email, password })

      props.setUser({
        ...response.data.user,
        role: response.data.schools[0].roleName,
      })
    } catch (e) {
      props.setSnackbar({ open: true, message: e.message, severity: 'error' })
    } finally {
      setLoading(false)
    }
  }

  return (
    <AuthLayout title="Login" quote={``} quoteAuthor="">
      <Box>
        <TextField
          variant="outlined"
          margin="normal"
          fullWidth
          id="email"
          label="Email"
          name="email"
          type="email"
          autoComplete="email"
          autoFocus
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          InputProps={{
            sx: {
              backgroundColor: 'background.paper',
            },
          }}
        />
        <TextField
          variant="outlined"
          margin="normal"
          fullWidth
          name="password"
          label="Password"
          type="password"
          id="password"
          autoComplete="current-password"
          InputProps={{
            sx: {
              backgroundColor: 'background.paper',
            },
          }}
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          onKeyDown={(e) => {
            if (e.key === 'Enter') handleLogin()
          }}
        />
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-end',
            // justifyContent: 'space-between', // use for when forgot password link is visible
            alignItems: 'center',
            marginTop: 2,
          }}>
          {/* <Button
            onClick={() => history.push('/forgot-password')}
            variant="text"
            sx={{
              weight: 1,
              fontFamily: 'Inter',
              fontWeight: 'bold',
              fontSize: 14,
              textDecoration: 'none',
              borderRadius: 1,
            }}>
            Forgot Password?
          </Button> */}
          <LoadingButton
            loading={loading}
            sx={{
              color: 'primary.contrastText',
              fontFamily: 'Inter',
              fontWeight: 'bold',
              fontSize: 14,
              my: 1,
              borderRadius: 1,
            }}
            color="primary"
            variant="contained"
            onClick={handleLogin}>
            Login
          </LoadingButton>
        </Box>
      </Box>
    </AuthLayout>
  )
}

const mapDispatchToProps = {
  setUser,
  setSnackbar,
}

export default connect(null, mapDispatchToProps)(LoginPage)
