import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { Box, TextField } from '@mui/material'

import api from 'lib/api'
import { setSnackbar } from 'reducers/ui'
import Modal from 'components/Modal'

const EditSchoolModal = (props) => {
  const { selectedSchool, open, onClose } = props

  const [name, setName] = useState('')
  const [address, setAddress] = useState('')
  const [city, setCity] = useState('')
  const [state, setState] = useState('')
  const [zipCode, setZipCode] = useState('')
  const [contactEmail, setContactEmail] = useState('')
  const [contactPhoneNumber, setContactPhoneNumber] = useState('')

  useEffect(() => {
    setName(selectedSchool?.name || '')
    setAddress(selectedSchool?.address || '')
    setCity(selectedSchool?.city || '')
    setState(selectedSchool?.state || '')
    setZipCode(selectedSchool?.zipCode || '')
    setContactEmail(selectedSchool?.contactEmail || '')
    setContactPhoneNumber(selectedSchool?.contactPhoneNumber || '')
  }, [selectedSchool])

  const onSubmit = async () => {
    try {
      await api.school.update({
        schoolId: selectedSchool.id,
        name,
        address,
        city,
        state,
        zipCode,
        contactEmail,
        contactPhoneNumber,
      })

      setName('')
      setAddress('')
      setCity('')
      setState('')
      setZipCode('')
      setContactEmail('')
      setContactPhoneNumber('')

      onClose()
    } catch (error) {
      props.setSnackbar({
        open: true,
        severity: 'error',
        message: error.message,
      })
    }
  }

  return (
    <Modal
      open={open}
      onClose={onClose}
      onSubmit={onSubmit}
      title="Edit School"
      submitText="Save"
      cancelText="Cancel">
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: 2,
          width: 400,
          padding: 2,
        }}>
        <TextField
          fullWidth
          label="Name"
          type="text"
          autoComplete="organization"
          value={name}
          onChange={(e) => setName(e.target.value)}
        />
        <TextField
          fullWidth
          label="Address"
          type="text"
          autoComplete="street-address"
          value={address}
          onChange={(e) => setAddress(e.target.value)}
        />
        <TextField
          fullWidth
          label="City"
          value={city}
          onChange={(e) => setCity(e.target.value)}
        />
        <TextField
          fullWidth
          label="State"
          value={state}
          onChange={(e) => setState(e.target.value)}
        />
        <TextField
          fullWidth
          label="ZIP Code"
          type="number"
          autoComplete="postal-code"
          value={zipCode}
          onChange={(e) => setZipCode(e.target.value)}
        />
        <TextField
          fullWidth
          label="Contact Email"
          type="email"
          autoComplete="email"
          value={contactEmail}
          onChange={(e) => setContactEmail(e.target.value)}
        />
        <TextField
          fullWidth
          label="Contact Phone Number"
          type="tel"
          autoComplete="tel"
          value={contactPhoneNumber}
          onChange={(e) => setContactPhoneNumber(e.target.value)}
        />
      </Box>
    </Modal>
  )
}

const mapDispatchToProps = {
  setSnackbar,
}

export default connect(null, mapDispatchToProps)(EditSchoolModal)
