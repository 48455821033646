import React from 'react'
import { Avatar, Typography } from '@mui/material'
import { connect } from 'react-redux'

const CustomAvatar = (props) => {
  const { user, preview, selectedUser } = props

  const getInitials = (firstName, lastName) => {
    const firstInitial = firstName ? firstName.charAt(0) : ''
    const lastInitial = lastName ? lastName.charAt(0) : ''
    return `${firstInitial}${lastInitial}`
  }

  const getColorfulBackgroundColor = (email) => {

  if (!email || typeof email !== 'string') {
      return '#808080' // default color if no email or invalid email
    }

    let hash = 0
    for (let i = 0; i < email.length; i++) {
      hash = email.charCodeAt(i) + ((hash << 5) - hash)
    }

    let r = (hash >> 16) & 0xff
    let g = (hash >> 8) & 0xff
    let b = hash & 0xff

    r = Math.floor((r + 200) / 2)
    g = Math.floor((g + 200) / 2)
    b = Math.floor((b + 200) / 2)

    const hexColor = `#${((1 << 24) + (r << 16) + (g << 8) + b)
      .toString(16)
      .slice(1)}`
    return hexColor
  }

  const renderAvatar = (user) => {
    if (user.profileImageLink || preview) {
      return (
        <Avatar
          onClick={props.onClick}
          variant="rounded"
          sx={{
            width: props?.sx?.width,
            height: props?.sx?.height,
            borderRadius: '25%',
          }}
          src={preview ? preview : user.profileImageLink}
          alt="Profile Image"
        />
      )
    } else {
      const initials = getInitials(user.firstName, user.lastName)
      const backgroundColor = getColorfulBackgroundColor(user.email)
      return (
        <Avatar
          onClick={props.onClick}
          variant="rounded"
          sx={{
            backgroundColor,
            width: props?.sx?.width,
            height: props?.sx?.height,
            borderRadius: '25%',
          }}
          alt="Initials Avatar">
          <Typography fontFamily="Inter" fontSize={props?.sx?.fontSize || 40}>
            {initials}
          </Typography>
        </Avatar>
      )
    }
  }

  return renderAvatar(selectedUser ? selectedUser : user)
}

const mapStateToProps = (state) => ({
  user: state.user,
})

export default connect(mapStateToProps)(CustomAvatar)
