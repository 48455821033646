import React, { useState } from 'react'
import { connect } from 'react-redux'
import { Box, TextField, Typography } from '@mui/material'

import api from 'lib/api'
import { setSnackbar } from 'reducers/ui'
import Modal from 'components/Modal'
import PlanSelector from 'components/selectors/PlanSelector'

const CreateSchoolAdminModal = (props) => {
  const { open, onClose } = props

  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')

  const [schoolName, setSchoolName] = useState('')
  const [schoolAddress, setSchoolAddress] = useState('')
  const [schoolCity, setSchoolCity] = useState('')
  const [schoolState, setSchoolState] = useState('')
  const [schoolZipCode, setSchoolZipCode] = useState('')
  const [schoolContactEmail, setSchoolContactEmail] = useState('')
  const [schoolContactPhoneNumber, setSchoolContactPhoneNumber] = useState('')
  const [schoolPlanId, setSchoolPlanId] = useState()

  const onSubmit = async () => {
    try {
      await api.user.auth.register({
        email,
        password,
        firstName,
        lastName,
        rolesId: 2,
        school: {
          name: schoolName,
          address: schoolAddress,
          city: schoolCity,
          state: schoolState,
          zipCode: schoolZipCode,
          contactEmail: schoolContactEmail,
          contactPhoneNumber: schoolContactPhoneNumber,
          planId: schoolPlanId,
        },
      })

      handleClose()
    } catch (error) {
      props.setSnackbar({
        open: true,
        severity: 'error',
        message: error.message,
      })
    }
  }

  const handleClose = () => {
    setEmail('')
    setPassword('')
    setFirstName('')
    setLastName('')

    setSchoolName('')
    setSchoolAddress('')
    setSchoolCity('')
    setSchoolState('')
    setSchoolZipCode('')
    setSchoolContactEmail('')
    setSchoolContactPhoneNumber('')
    setSchoolPlanId()

    onClose()
  }

  return (
    <Modal
      open={open}
      onClose={handleClose}
      onSubmit={onSubmit}
      title="Create School Admin"
      submitText="Create"
      cancelText="Cancel">
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: 2,
          width: 400,
          padding: 2,
        }}>
        <TextField
          fullWidth
          label="Email"
          type="email"
          autoComplete="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <TextField
          fullWidth
          label="Password"
          type="password"
          autoComplete="new-password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
        <TextField
          fullWidth
          label="First Name"
          type="text"
          autoComplete="given-name"
          value={firstName}
          onChange={(e) => setFirstName(e.target.value)}
        />
        <TextField
          fullWidth
          label="Last Name"
          type="text"
          autoComplete="family-name"
          value={lastName}
          onChange={(e) => setLastName(e.target.value)}
        />

        <Typography variant="subtitle1" mt={2}>
          School Information
        </Typography>
        <TextField
          fullWidth
          label="School Name"
          type="text"
          autoComplete="organization"
          value={schoolName}
          onChange={(e) => setSchoolName(e.target.value)}
        />
        <TextField
          fullWidth
          label="School Address"
          type="text"
          autoComplete="street-address"
          value={schoolAddress}
          onChange={(e) => setSchoolAddress(e.target.value)}
        />
        <TextField
          fullWidth
          label="School City"
          type="text"
          value={schoolCity}
          onChange={(e) => setSchoolCity(e.target.value)}
        />
        <TextField
          fullWidth
          label="School State"
          type="text"
          value={schoolState}
          onChange={(e) => setSchoolState(e.target.value)}
        />
        <TextField
          fullWidth
          label="School ZIP Code"
          type="number"
          autoComplete="postal-code"
          value={schoolZipCode}
          onChange={(e) => setSchoolZipCode(e.target.value)}
        />
        <TextField
          fullWidth
          label="School Contact Email"
          type="email"
          autoComplete="email"
          value={schoolContactEmail}
          onChange={(e) => setSchoolContactEmail(e.target.value)}
        />
        <TextField
          fullWidth
          label="School Contact Phone Number"
          type="tel"
          autoComplete="tel"
          value={schoolContactPhoneNumber}
          onChange={(e) => setSchoolContactPhoneNumber(e.target.value)}
        />
        <PlanSelector planId={schoolPlanId} setPlanId={setSchoolPlanId} />
      </Box>
    </Modal>
  )
}

const mapDispatchToProps = {
  setSnackbar,
}

export default connect(null, mapDispatchToProps)(CreateSchoolAdminModal)
