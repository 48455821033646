import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { Box, Button, Grid, Skeleton, Typography } from '@mui/material'
import { Add } from '@mui/icons-material'

import api from 'lib/api'
import { setSnackbar } from 'reducers/ui'
import MaterialItem from './MaterialItem'
import { useAuth0 } from '@auth0/auth0-react'

const MaterialList = (props) => {
  const { getAccessTokenSilently } = useAuth0()
  const {
    currentSchoolId,
    currentClassroomTeacherId,
    courseId,
    noTitle,
    noButton,
    setSelectedMaterial,
    materialDialogOpen,
    toggleOpenDialog,
    materials: forceMaterials,
  } = props

  const [materials, setMaterials] = useState([])
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    const fetchCourse = async () => {
      const token = await getAccessTokenSilently()
      setLoading(true)
      try {
        const response = await api.course.get({
          schoolId: currentSchoolId,
          classroomTeacherId: currentClassroomTeacherId,
          courseId,
        }, token)

        setMaterials(response.data.materials)
      } catch (e) {
        props.setSnackbar({ open: true, message: e.message, severity: 'error' })
      } finally {
        setSelectedMaterial()
        setLoading(false)
      }
    }

    if (!materialDialogOpen && courseId) fetchCourse()
    if (!courseId && forceMaterials) setMaterials(forceMaterials)
    // eslint-disable-next-line
  }, [courseId, materialDialogOpen])

  return loading ? (
    <Box sx={{ display: 'flex', flexDirection: 'column', my: 1, gap: 2 }}>
      {Array.from({ length: 4 }, (_, index) => (
        <Skeleton
          key={index}
          variant="rectangular"
          sx={{ width: '100%', height: 80 }}
        />
      ))}
    </Box>
  ) : (
    <>
      {!noTitle ||
        (noTitle && materials?.length > 0 && (
          <Grid item xs={12}>
            <Typography
              sx={{
                fontFamily: 'Inter',
                fontSize: '16px',
                fontWeight: 600,
                lineHeight: '19.36px',
                letterSpacing: '0.02em',
                textAlign: 'left',
                display: 'flex',
                mt: 2,
                mb: 1,
              }}>
              Course Materials
            </Typography>
          </Grid>
        ))}
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
        {materials?.map((material, index) => (
          <MaterialItem
            key={index}
            material={material}
            courseId={courseId}
            onClick={() => {
              setSelectedMaterial(material)
              toggleOpenDialog()
            }}
            onRemove={() => {
              const updatedMaterials = [...materials]
              updatedMaterials.splice(index, 1)
              setMaterials(updatedMaterials)
              props.setMaterials(updatedMaterials)
            }}
          />
        ))}
      </Box>
      {!noButton && (
        <Grid item xs={12} sx={{ my: noTitle ? 1 : 0 }}>
          <Button
            onClick={toggleOpenDialog}
            color="primary"
            variant="outlined"
            sx={{
              fontFamily: 'Inter',
              fontSize: '14px',
              fontWeight: 600,
              borderRadius: 1,
            }}
            startIcon={<Add />}>
            Add Material
          </Button>
        </Grid>
      )}
    </>
  )
}

const mapStateToProps = (state) => ({
  currentSchoolId: state.user.currentSchoolId,
  currentClassroomTeacherId: state.user.currentClassroomTeacherId,
})

const mapDispatchToProps = {
  setSnackbar,
}

export default connect(mapStateToProps, mapDispatchToProps)(MaterialList)
