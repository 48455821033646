import React from 'react'
import { Box, Button, Typography } from '@mui/material'

import CustomPageIndicator from 'components/teacher/CustomPageIndicator'

const CustomPaginationBar = (props) => {
  const { page, viewAll, length } = props

  const handlePageChange = (direction) => {
    props.setPage((prev) => prev + direction)
  }

  const handleViewAll = () => {
    props.toggleViewAll()
  }

  return (
    <Box
      sx={{
        display: 'flex',
        gap: 2,
        marginTop: 2,
      }}>
      {page > 1 && (
        <CustomPageIndicator
          number={page - 1}
          isActive={false}
          onClick={() => handlePageChange(-1)}
        />
      )}
      <CustomPageIndicator number={page} isActive={true} />
      {page < length && (
        <CustomPageIndicator
          number={page + 1}
          isActive={false}
          onClick={() => handlePageChange(1)}
        />
      )}
      <Button
        variant={viewAll ? 'contained' : 'outlined'}
        onClick={handleViewAll}>
        <Typography fontFamily="Inter" fontWeight="bold">
          View All
        </Typography>
      </Button>
    </Box>
  )
}

export default CustomPaginationBar
