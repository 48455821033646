import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { Box, Button, TableCell, Typography } from '@mui/material'

import api from 'lib/api'
import { setSnackbar } from 'reducers/ui'
import EnhancedTable from 'components/DataTable'
import CreateSchoolAdminModal from 'components/modals/CreateSchoolAdminModal'
import EditSchoolAdminModal from 'components/modals/EditSchoolAdminModal'
import { useAuth0 } from '@auth0/auth0-react'

const SchoolAdminPage = (props) => {
  const { master } = props
  const { getAccessTokenSilently } = useAuth0()

  const [admins, setAdmins] = useState([])
  const [createSchoolAdminModalOpen, setCreateSchoolAdminModalOpen] =
    useState(false)
  const [editSchoolAdminModalOpen, setEditSchoolAdminModalOpen] =
    useState(false)
  const [selectedSchoolAdmin, setSelectedSchoolAdmin] = useState()

  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(10)

  const toggleCreateSchoolAdminModalOpen = () => {
    setCreateSchoolAdminModalOpen(!createSchoolAdminModalOpen)
  }

  const toggleEditSchoolAdminModalOpen = () => {
    setEditSchoolAdminModalOpen(!editSchoolAdminModalOpen)
  }

  const rowCells = (row) => (
    <>
      <TableCell align="left">{row.id}</TableCell>
      <TableCell align="right">{row.email}</TableCell>
      <TableCell align="right">{row.firstName}</TableCell>
      <TableCell align="right">{row.lastName}</TableCell>
      <TableCell align="right">{row.isActive ? 'YES' : 'NO'}</TableCell>
      <TableCell align="right">
        {row.schools.map((school) => school.name).join(', ')}
      </TableCell>
    </>
  )

  const headCells = [
    {
      id: 'id',
      numeric: false,
      disablePadding: false,
      label: 'ID',
    },
    {
      id: 'email',
      numeric: true,
      disablePadding: false,
      label: 'Email',
    },
    {
      id: 'firstName',
      numeric: true,
      disablePadding: false,
      label: 'First Name',
    },
    {
      id: 'lastName',
      numeric: true,
      disablePadding: false,
      label: 'Last Name',
    },
    {
      id: 'isActive',
      numeric: true,
      disablePadding: false,
      label: 'Active',
    },
    {
      id: 'schools',
      numeric: true,
      disablePadding: false,
      label: 'Schools',
    },
  ]

  useEffect(() => {
    const fetchSchoolAdmins = async () => {
      try {
        const token = await getAccessTokenSilently()
        const response = await api.user.list({
          roleId: 2,
          page: page + 1,
          rowsPerPage,
        }, token)
        if (response.data?.users?.length === 0 && page > 0)
          setPage((page) => page - 1)
        else if (
          response.data?.users?.length > 0 &&
          !(admins.length > rowsPerPage * page)
        )
          if (page > 0)
            setAdmins((admins) =>
              [
                ...admins,
                ...response.data.users.map((item) => ({
                  ...item.user,
                  schools: item.schools,
                })),
              ].filter(
                (value, index, self) =>
                  index === self.findIndex((t) => t.id === value.id)
              )
            )
          else
            setAdmins(
              response.data.users.map((item) => ({
                ...item.user,
                schools: item.schools,
              }))
            )
        else if (response.data?.users?.length > 0 && page === 0)
          setAdmins(
            response.data.users.map((item) => ({
              ...item.user,
              schools: item.schools,
            }))
          )
      } catch (error) {
        props.setSnackbar({
          open: true,
          message: `Error fetching school admins: ${error.message}`,
          severity: 'error',
        })
      }
    }

    if (!createSchoolAdminModalOpen && !editSchoolAdminModalOpen)
      fetchSchoolAdmins()
    // eslint-disable-next-line
  }, [createSchoolAdminModalOpen, editSchoolAdminModalOpen, page, rowsPerPage])

  return (
    <Box>
      <Typography variant="h4" textAlign="center" mb={2}>
        School Admins
      </Typography>

      <Box sx={{ display: 'flex', justifyContent: 'end', marginBottom: 2 }}>
        <Button
          variant="contained"
          color="primary"
          onClick={toggleCreateSchoolAdminModalOpen}>
          Create School Admin
        </Button>
      </Box>

      <EnhancedTable
        page={page}
        setPage={setPage}
        rowsPerPage={rowsPerPage}
        setRowsPerPage={setRowsPerPage}
        filterDisabled
        selectionDisabled
        rows={admins}
        rowCells={rowCells}
        headCells={headCells}
        onRowClick={(schoolAdmin) => {
          if (master === true) {
            setSelectedSchoolAdmin(schoolAdmin)
            toggleEditSchoolAdminModalOpen()
          }
        }}
      />

      <CreateSchoolAdminModal
        open={createSchoolAdminModalOpen}
        onClose={toggleCreateSchoolAdminModalOpen}
      />

      <EditSchoolAdminModal
        selectedSchoolAdmin={selectedSchoolAdmin}
        open={editSchoolAdminModalOpen}
        onClose={toggleEditSchoolAdminModalOpen}
      />
    </Box>
  )
}

const mapStateToProps = (state) => ({
  master: state.user.master,
})

const mapDispatchToProps = {
  setSnackbar,
}

export default connect(mapStateToProps, mapDispatchToProps)(SchoolAdminPage)
