import React from 'react'

import AuthLayout from 'layouts/Auth'
import QrCodeImage from 'assets/qr-code-static.png'

const imageSize = '240rem'

const QR = () => {
  return (
    <AuthLayout
      title=""
      quote={`Welcome to the future of education.`}
      quoteAuthor="">
      <img src={QrCodeImage} alt="QR" width={imageSize} height={imageSize} />
    </AuthLayout>
  )
}

export default QR
