import React, { useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { connect } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { setCurrentSchoolId, setUser, clearUser } from 'reducers/user';
import { setSnackbar } from 'reducers/ui';
import api from 'lib/api';
import { getAuth0Role } from 'utils/auth0';
import { Box, Typography, CircularProgress } from '@mui/material';
import { RepeatOneSharp } from '@mui/icons-material';

const Auth0Callback = ({ setUser, setSnackbar, setCurrentSchoolId, setCurrentClassroomTeacherId, clearUser }) => {
    const { user, isAuthenticated, isLoading, getAccessTokenSilently } = useAuth0();
    const history = useHistory();
    const location = useLocation();

    useEffect(() => {
        const handleAuthentication = async () => {
            if (isAuthenticated && user) {
                clearUser();
                const auth0Role = getAuth0Role(user);

                try {
                    const token = await getAccessTokenSilently();

                    try {
                        const response = await api.user.auth.login({
                            email: user.email,
                        }, token);

                        setUser({
                            ...response.data.user,
                            auth0Role: auth0Role,
                            role: auth0Role,
                        });

                        if (response.data.schools?.[0]?.classroomTeacherId) {
                            setCurrentClassroomTeacherId(response.data.schools[0].classroomTeacherId);
                        }

                        setCurrentSchoolId(response.data.schools[0].schoolsId);
                        setSnackbar({ open: true, message: 'Login successful', severity: 'success' });
                        const returnTo = location.state?.returnTo || '/classes';
                        history.push(returnTo);

                    } catch (error) {

                        // check if this is a "user not found" error
                        if (error.response?.status === 404 || 
                            error.message?.toLowerCase().includes('user not found')) {
                            
                            console.log("New user detected, redirecting to account creation");
                            
                            // Get URL parameters if they exist
                            const params = new URLSearchParams(location.search);
                            const classCode = params.get('c');
                            const teacherFirstName = params.get('tF');
                            const teacherLastName = params.get('tL');

                            // Build the redirect URL with any existing parameters
                            let redirectUrl = '/student/create';
                            const searchParams = new URLSearchParams();
                            
                            if (classCode) searchParams.set('c', classCode);
                            if (teacherFirstName) searchParams.set('tF', teacherFirstName);
                            if (teacherLastName) searchParams.set('tL', teacherLastName);
                            
                            // Add the search parameters if any exist
                            if ([...searchParams].length > 0) {
                                redirectUrl += `?${searchParams.toString()}`;
                            }

                            history.push(redirectUrl);
                        } else {
                            // Handle other types of errors
                            console.error('Error in authentication process:', error);
                            setSnackbar({ 
                                open: true, 
                                message: 'Login failed: ' + (error.message || 'Unknown error'), 
                                severity: 'error' 
                            });
                            history.push('/login');
                        }
                    }
                } catch (error) {
                    // Handle Auth0 token error
                    console.error('Error getting Auth0 token:', error);
                    setSnackbar({ 
                        open: true, 
                        message: 'Authentication failed: Could not get access token', 
                        severity: 'error' 
                    });
                    history.push('/login');
                }
            }
        };

        if (!isLoading) {
            handleAuthentication();
        }
    }, [isAuthenticated, isLoading, user, history, setUser, setSnackbar, getAccessTokenSilently, location]);

    if (isLoading) {
        return (
            <Box 
                sx={{
                    minHeight: '100vh',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    backgroundColor: 'background.default'
                }}
            >
                <Box sx={{ textAlign: 'center' }}>
                    <CircularProgress size={40} sx={{ mb: 3 }} />
                    <Typography variant="h6" sx={{ mb: 1 }}>
                        Loading...
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                        Please wait while we process your authentication
                    </Typography>
                </Box>
            </Box>
        );
    }

    return (
        <Box 
            sx={{
                minHeight: '100vh',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                backgroundColor: 'background.default'
            }}
        >
            <Box sx={{ textAlign: 'center' }}>
                <CircularProgress size={40} sx={{ mb: 3 }} />
                <Typography variant="h6" sx={{ mb: 1 }}>
                    Processing authentication...
                </Typography>
                <Typography variant="body2" color="text.secondary">
                    You'll be redirected shortly
                </Typography>
            </Box>
        </Box>
    );
};

const mapDispatchToProps = {
    setUser,
    setSnackbar,
    setCurrentSchoolId,
    clearUser
};

export default connect(null, mapDispatchToProps)(Auth0Callback);