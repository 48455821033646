import React from 'react'
import { Typography } from '@mui/material'

const ParagraphSection = ({ title, description }) => {
  return (
    <Typography fontFamily="Inter">
      <strong>{`${title}: `}</strong> {`${description}`}
    </Typography>
  )
}

export default ParagraphSection
