import axios from 'axios'
import { store } from 'store'

export const instance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
})

instance.interceptors.response.use(
  (response) => {
    return response
  },
  (error) => {
    if (error.response && error.response.data && error.response.data.message)
      error.message = error.response.data.message

    return Promise.reject(error)
  }
)

const createAuthHeaders = (token) => {
  return {
    headers: {
      'Authorization': `Bearer ${token}`,
      'Content-Type': 'application/json'
    }
  };
};

const authInstance = () => {
  const state = store.getState()
  const authToken = state.user.token

  if (authToken) {
    instance.defaults.headers.common['Authorization'] = `Bearer ${authToken}`
  }

  return instance
}

const userAuthLogin = ({ email }, token) => {
  return instance.post(`/users/auth/login`, {
    email,
  },
    createAuthHeaders(token)
  )
}

const userAuthRegister = ({
  email,
  password,
  firstName,
  lastName,
  rolesId,
  school,
  joinSchool,
  auth0_user_id
}, token) => {
  return instance.post(`/users/auth/register`, {
    email,
    password,
    firstName,
    lastName,
    rolesId,
    school,
    joinSchool,
    auth0_user_id
  }, createAuthHeaders(token))
}

const adminAuthLogin = ({ email, password }) => {
  return instance.post(`/admins/auth/login`, { email, password })
}

const adminAuthCreate = ({ email, password, firstName, lastName }) => {
  return authInstance().post(`/admins/auth/new`, {
    email,
    password,
    firstName,
    lastName,
  })
}

const adminList = ({ page = 1, rowsPerPage = 10 }) => {
  return authInstance().get(`/admins/?page=${page}&per_page=${rowsPerPage}`)
}

const adminUpdate = ({
  email,
  newPassword,
  oldPassword,
  firstName,
  lastName,
}) => {
  return authInstance().patch(`/admins/`, {
    email,
    newPassword,
    oldPassword,
    firstName,
    lastName,
  })
}

const adminMasterUpdate = ({
  adminId,
  email,
  newPassword,
  oldPassword,
  firstName,
  lastName,
}) => {
  return authInstance().patch(`/admins/${adminId}`, {
    email,
    newPassword,
    oldPassword,
    firstName,
    lastName,
  })
}

const roleList = ({ }, token) => {
  return authInstance().get(`/roles/`,
    createAuthHeaders(token)
  )
}

const llmRolesList = ({ }, token) => {
  return authInstance().get(`/llm_roles/`,
    createAuthHeaders(token)
  )
}

const planCreate = ({ name, description, price, durationInMonths }) => {
  return authInstance().post(`/plans/`, {
    name,
    description,
    price,
    durationInMonths,
  })
}

const planList = () => {
  return authInstance().get(`/plans/`)
}

const planUpdate = ({ planId, name, description, price, durationInMonths }) => {
  return authInstance().patch(`/plans/${planId}`, {
    name,
    description,
    price,
    durationInMonths,
  })
}

const planDelete = ({ planId }) => {
  return authInstance().delete(`/plans/${planId}`)
}

const schoolCreate = ({
  name,
  address,
  city,
  state,
  zipCode,
  contactEmail,
  contactPhoneNumber,
  planId,
}, token) => {
  return authInstance().post(`/schools/`, {
    name,
    address,
    city,
    state,
    zipCode,
    contactEmail,
    contactPhoneNumber,
    planId,
  },
    createAuthHeaders(token)
  )
}

const schoolList = async ({ page = 1, rowsPerPage = 10 }, token) => {
  try {
    const response = await instance.get(
      `/schools/?page=${page}&per_page=${rowsPerPage}`,
      createAuthHeaders(token)
    );
    return response;
  } catch (error) {
    console.error("Error in schoolList:", error);
    throw error;
  }
}

const schoolGet = ({ schoolId }) => {
  return authInstance().get(`/schools/${schoolId}`)
}

const schoolUpdate = ({
  schoolId,
  name,
  address,
  city,
  state,
  zipCode,
  contactEmail,
  contactPhoneNumber,
}) => {
  return authInstance().patch(`/schools/${schoolId}`, {
    name,
    address,
    city,
    state,
    zipCode,
    contactEmail,
    contactPhoneNumber,
  })
}

const schoolGenerateCode = ({ schoolId }, token) => {
  return authInstance().post(`/schools/${schoolId}/code/`,
    createAuthHeaders(token)
  )
}

const schoolListCodes = ({ schoolId }, token) => {
  return authInstance().get(`/schools/${schoolId}/code/`,
    createAuthHeaders(token)
  )
}

const inviteCreate = ({ schoolId, email, expiresIn }, token) => {
  return authInstance().post(`/schools/${schoolId}/invite/`, {
    email,
    expiresIn,
  },
    createAuthHeaders(token)
  )
}

const inviteList = ({ schoolId }, token) => {
  return authInstance().get(`/schools/${schoolId}/invite/`,
    createAuthHeaders(token)
  )
}

const classroomCreate = ({
  schoolId,
  name,
  startDate,
  endDate,
  maxPupils,
  pupils,
  teachers,
}, token) => {
  return authInstance().post(`/schools/${schoolId}/classrooms/`, {
    schoolId,
    name,
    startDate,
    endDate,
    maxPupils,
    pupils,
    teachers,
  }, createAuthHeaders(token))
}

const classroomList = ({
  schoolId,
  page = 1,
  rowsPerPage = 10,
  teachersPerPage = 10,
  pupilsPerPage = 10,
  mine,
}, token) => {
  return authInstance().get(
    `/schools/${schoolId}/classrooms/?page=${page}&per_page=${rowsPerPage}&per_page_pupils=${pupilsPerPage}&per_page_teachers=${teachersPerPage}${mine ? '&mine=True' : ''
    }`,
    createAuthHeaders(token)
  )
}

const classroomGet = ({ schoolId, classroomId }, token) => {
  return authInstance().get(`/schools/${schoolId}/classrooms/${classroomId}`,
    createAuthHeaders(token)
  )
}

const classroomGetCode = ({ schoolId, classroomId }, token) => {
  return authInstance().get(
    `/schools/${schoolId}/classrooms/${classroomId}/code`,
    createAuthHeaders(token)
  )
}

const enrollStudentInClassroom = ({ schoolId, classroomCode, userId }, token) => {
  return authInstance().post(
    `/schools/${schoolId}/classrooms/${classroomCode}/enroll/${userId}`,
    {},
    createAuthHeaders(token)
  )
}

const classroomUpdate = ({
  schoolId,
  classroomId,
  name,
  startDate,
  endDate,
  maxPupils,
}, token) => {
  return authInstance().patch(
    `/schools/${schoolId}/classrooms/${classroomId}`,
    {
      name,
      startDate,
      endDate,
      maxPupils,
    },
    createAuthHeaders(token)
  )
}

const classroomUpdatePupils = ({
  schoolId,
  classroomId,
  pupils,
  teachers,
  removePupils,
  removeTeachers,
}, token) => {
  return authInstance().put(`/schools/${schoolId}/classrooms/${classroomId}`, {
    pupils,
    teachers,
    removePupils,
    removeTeachers,
  },
    createAuthHeaders(token)
  )
}

const courseCreate = ({
  schoolId,
  classroomTeacherId,
  name,
  description,
  grade,
  activities,
  materials,
}, token) => {
  return authInstance().post(
    `/schools/${schoolId}/classrooms/${classroomTeacherId}/courses/`,
    {
      name,
      description,
      grade,
      activities,
      materials,
    },
    createAuthHeaders(token)
  )
}

const courseList = ({
  schoolId,
  classroomTeacherId,
  page = 1,
  rowsPerPage = 10,
}, token) => {
  return authInstance().get(
    `/schools/${schoolId}/classrooms/${classroomTeacherId}/courses/?page=${page}&per_page=${rowsPerPage}`,
    createAuthHeaders(token)
  )
}

const courseGet = ({ schoolId, classroomTeacherId, courseId, per_page_materials }, token) => {
  return authInstance().get(
    `/schools/${schoolId}/classrooms/${classroomTeacherId}/courses/${courseId}${per_page_materials ? `?per_page_materials=${per_page_materials}` : ''}`,
    createAuthHeaders(token)
  )
}

const courseUpdate = ({
  schoolId,
  classroomTeacherId,
  courseId,
  name,
  description,
  grade,
}, token) => {
  return authInstance().patch(
    `/schools/${schoolId}/classrooms/${classroomTeacherId}/courses/${courseId}`,
    {
      name,
      description,
      grade,
    },
    createAuthHeaders(token)
  )
}

const courseUpdateActivitiesAndMaterials = ({
  schoolId,
  classroomTeacherId,
  courseId,
  activities,
  materials,
  removeActivities,
  removeMaterials,
  removeObjectives,
  removeQuestions,
}, token, per_page_materials) => {
  return authInstance().put(
    `/schools/${schoolId}/classrooms/${classroomTeacherId}/courses/${courseId}${per_page_materials ? `?per_page_materials=${per_page_materials}` : ''}`,
    {
      activities,
      materials,
      removeActivities,
      removeMaterials,
      removeObjectives,
      removeQuestions,
    },
    createAuthHeaders(token)
  )
}

const courseGetAnalytics = ({
  schoolId,
  classroomTeacherId,
  courseId,
  activityId,
  objectiveId,
  pupilId,
}, token) => {
  return authInstance().get(
    `/schools/${schoolId}/classrooms/${classroomTeacherId}/courses/${courseId}/activities/${activityId}/objectives/${objectiveId}/llm_analytics/${pupilId}`,
    createAuthHeaders(token)
  )
}

const courseGetSentiments = ({
  schoolId,
  classroomTeacherId,
  courseId,
  activityId,
  pupilId,
}, token) => {
  return authInstance().get(
    `/schools/${schoolId}/classrooms/${classroomTeacherId}/courses/${courseId}/activities/${activityId}/sentiment_analytics/${pupilId}`,
    createAuthHeaders(token)
  )
}

const activityGetChatHistory = ({
  schoolId,
  classroomTeacherId,
  courseId,
  activityId,
  perPage = 10,
  page = 1,
}, token) => {
  return authInstance().get(
    `/schools/${schoolId}/classrooms/${classroomTeacherId}/courses/${courseId}/activities/${activityId}/chat/?per_page=${perPage}&page=${page}`,
    createAuthHeaders(token)
  )
}

const activitySendMessage = async ({
  schoolId,
  classroomTeacherId,
  courseId,
  activityId,
  message,
}, token) => {
  return fetch(
    `${process.env.REACT_APP_API_URL}schools/${schoolId}/classrooms/${classroomTeacherId}/courses/${courseId}/activities/${activityId}/chat/`,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      },
      body: JSON.stringify({ message })
    }
  )
}

const activityUpdate = ({
  schoolId,
  classroomTeacherId,
  courseId,
  activityId,
  name,
  description,
  promptInstructions,
  llmRolesId,
}, token) => {
  return authInstance().patch(
    `/schools/${schoolId}/classrooms/${classroomTeacherId}/courses/${courseId}/activities/${activityId}`,
    {
      name,
      description,
      promptInstructions,
      llmRolesId,
    },
    createAuthHeaders(token)
  )
}

const activityUpdateObjectives = ({
  schoolId,
  classroomTeacherId,
  courseId,
  activityId,
  objectives,
}, token) => {
  return authInstance().patch(
    `/schools/${schoolId}/classrooms/${classroomTeacherId}/courses/${courseId}/activities/${activityId}/objectives`,
    { objectives },
    createAuthHeaders(token)
  )
}

const activityAddObjectiveOrQuestion = ({
  schoolId,
  classroomTeacherId,
  courseId,
  activityId,
  objectives,
  questions,
}, token) => {
  return authInstance().put(
    `/schools/${schoolId}/classrooms/${classroomTeacherId}/courses/${courseId}/activities/${activityId}`,
    {
      objectives,
      questions,
    },
    createAuthHeaders(token)
  )
}

const noteGet = ({ schoolId, classroomTeacherId, courseId, studentId }, token) => {
  return authInstance().get(
    `/schools/${schoolId}/classrooms/${classroomTeacherId}/courses/${courseId}/notes/${studentId}`,
    createAuthHeaders(token)
  )
}

const noteUpdate = ({
  schoolId,
  classroomTeacherId,
  courseId,
  studentId,
  note,
}, token) => {
  return authInstance().put(
    `/schools/${schoolId}/classrooms/${classroomTeacherId}/courses/${courseId}/notes/${studentId}`,
    { note },
    createAuthHeaders(token)
  )
}

const userList = ({
  schoolId,
  roleId,
  page = 1,
  rowsPerPage = 10
}, token) => {
  let url = `/users/?page=${page}&per_page=${rowsPerPage}`

  if (schoolId && roleId) {
    url += `&schools_id=${schoolId}&roles_id=${roleId}`
  } else if (schoolId) {
    url += `&schools_id=${schoolId}`
  } else if (roleId) {
    url += `&roles_id=${roleId}`
  }

  return authInstance().get(url, createAuthHeaders(token))
}

const userUpdate = ({
  email,
  firstName,
  lastName,
  profileImageLink
}, token) => {
  return instance.patch(`/users/me`, {
    email,
    firstName,
    lastName,
    profileImageLink
  }, createAuthHeaders(token))
}

const userMasterUpdate = ({
  userId,
  email,
  firstName,
  lastName,
  profileImageLink
}, token) => {
  return instance.patch(`/users/${userId}`, {
    email,
    firstName,
    lastName,
    profileImageLink
  }, createAuthHeaders(token))
}

const mediaUpload = ({ schoolId, formData }, token) => {
  return instance.post(
    `/media/?school_id=${schoolId}`,
    formData,
    {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'multipart/form-data',
      }
    }
  )
}

const api = {
  admin: {
    auth: {
      login: adminAuthLogin,
      create: adminAuthCreate,
    },
    list: adminList,
    update: adminUpdate,
    masterUpdate: adminMasterUpdate,
  },
  user: {
    auth: {
      login: userAuthLogin,
      register: userAuthRegister,
    },
    list: userList,
    update: userUpdate,
    masterUpdate: userMasterUpdate,
  },
  role: { list: roleList },
  llmRoles: { list: llmRolesList },
  plan: {
    create: planCreate,
    list: planList,
    update: planUpdate,
    delete: planDelete,
  },
  school: {
    create: schoolCreate,
    list: schoolList,
    get: schoolGet,
    update: schoolUpdate,
    generateCode: schoolGenerateCode,
    listCodes: schoolListCodes,
  },
  invite: {
    create: inviteCreate,
    list: inviteList,
  },
  classroom: {
    create: classroomCreate,
    list: classroomList,
    get: classroomGet,
    update: classroomUpdate,
    updatePupils: classroomUpdatePupils,
    getCode: classroomGetCode,
    enroll: enrollStudentInClassroom
  },
  course: {
    create: courseCreate,
    list: courseList,
    get: courseGet,
    update: courseUpdate,
    updateActivitiesAndMaterials: courseUpdateActivitiesAndMaterials,
    getAnalytics: courseGetAnalytics,
    getSentiments: courseGetSentiments,
  },
  activity: {
    getChatHistory: activityGetChatHistory,
    sendMessage: activitySendMessage,
    update: activityUpdate,
    updateObjectives: activityUpdateObjectives,
    addObjectiveOrQuestion: activityAddObjectiveOrQuestion,
  },
  note: {
    get: noteGet,
    update: noteUpdate,
  },
  media: {
    upload: mediaUpload,
  },
}

export default api
