import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { Box, Button, IconButton, TableCell, Typography } from '@mui/material'
import { ManageAccounts } from '@mui/icons-material'
import moment from 'moment'

import api from 'lib/api'
import { setSnackbar } from 'reducers/ui'
import EnhancedTable from 'components/DataTable'
import SchoolSelector from 'components/selectors/SchoolSelector'
import CreateClassroomModal from 'components/modals/CreateClassroomModal'
import EditClassroomModal from 'components/modals/EditClassroomModal'
import EditClassroomUsersModal from 'components/modals/EditClassroomUsersModal'
import { useAuth0 } from '@auth0/auth0-react'

const ClassroomPage = (props) => {
  const { getAccessTokenSilently, user } = useAuth0()
  const [schoolId, setSchoolId] = useState()
  const [classrooms, setClassrooms] = useState([])
  const [createClassroomModalOpen, setCreateClassroomModalOpen] =
    useState(false)
  const [editClassroomModalOpen, setEditClassroomModalOpen] = useState(false)
  const [editClassroomUsersModalOpen, setEditClassroomUsersModalOpen] =
    useState(false)
  const [selectedClassroom, setSelectedClassroom] = useState()

  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(10)

  const toggleCreateClassroomModalOpen = () =>
    setCreateClassroomModalOpen(!createClassroomModalOpen)

  const toggleEditClassroomModalOpen = () =>
    setEditClassroomModalOpen(!editClassroomModalOpen)

  const toggleEditClassroomUsersModalOpen = () => {
    if (editClassroomUsersModalOpen) setSelectedClassroom()
    setEditClassroomUsersModalOpen(!editClassroomUsersModalOpen)
  }

  const rowCells = (row) => (
    <>
      <TableCell align="left">{row.id}</TableCell>
      <TableCell align="right">{row.name}</TableCell>
      <TableCell align="right">
        {moment(row.startDate).format('YYYY-MM-DD')}
      </TableCell>
      <TableCell align="right">
        {moment(row.endDate).format('YYYY-MM-DD')}
      </TableCell>
      <TableCell align="right">{row.maxPupils}</TableCell>
      <TableCell align="right">
        <IconButton
          onClick={(e) => {
            e.stopPropagation()
            setSelectedClassroom(row)
            toggleEditClassroomUsersModalOpen()
          }}>
          <ManageAccounts />
        </IconButton>
      </TableCell>
    </>
  )

  const headCells = [
    {
      id: 'id',
      numeric: false,
      disablePadding: false,
      label: 'ID',
    },
    {
      id: 'name',
      numeric: true,
      disablePadding: false,
      label: 'Name',
    },
    {
      id: 'startDate',
      numeric: true,
      disablePadding: false,
      label: 'Start Date',
    },
    {
      id: 'endDate',
      numeric: true,
      disablePadding: false,
      label: 'End Date',
    },
    {
      id: 'maxPupils',
      numeric: true,
      disablePadding: false,
      label: 'Max Pupils',
    },
    {
      id: 'actions',
      numeric: true,
      disablePadding: false,
      label: 'Manage Teachers & Students',
      sx: { width: 140 },
    },
  ]

  useEffect(() => {
    const fetchClassrooms = async () => {
      try {
        const token = await getAccessTokenSilently()
        const response = await api.classroom.list({
          schoolId,
          page: page + 1,
          rowsPerPage,
        }, token)
        if (response.data?.classrooms?.lengh === 0 && page > 0)
          setPage((page) => page - 1)
        else if (
          response.data?.classrooms?.length > 0 &&
          !(classrooms.length > rowsPerPage * page)
        )
          if (page > 0)
            setClassrooms((classrooms) =>
              [
                ...classrooms,
                ...response.data.classrooms.map((item) => ({
                  ...item.classroom,
                  pupils: item.pupils,
                  teachers: item.teachers,
                })),
              ].filter(
                (value, index, self) =>
                  index === self.findIndex((t) => t.id === value.id)
              )
            )
          else
            setClassrooms(
              response.data.classrooms.map((item) => ({
                ...item.classroom,
                pupils: item.pupils,
                teachers: item.teachers,
              }))
            )
        else if (response.data?.classrooms?.length > 0 && page === 0)
          setClassrooms(
            response.data.classrooms.map((item) => ({
              ...item.classroom,
              pupils: item.pupils,
              teachers: item.teachers,
            }))
          )
      } catch (error) {
        props.setSnackbar({
          open: true,
          message: `Error fetching classrooms: ${error.message}`,
          severity: 'error',
        })
      }
    }

    if (
      schoolId &&
      !createClassroomModalOpen &&
      !editClassroomModalOpen &&
      !editClassroomUsersModalOpen
    )
      fetchClassrooms()
    // eslint-disable-next-line
  }, [
    schoolId,
    createClassroomModalOpen,
    editClassroomModalOpen,
    editClassroomUsersModalOpen,
    page,
    rowsPerPage,
  ])

  return (
    <Box>
      <Typography variant="h4" textAlign="center" mb={2}>
        Classrooms
      </Typography>

      {schoolId && (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            marginBottom: 2,
          }}>
          <Box width={300}>
            <SchoolSelector
              clear={() => setClassrooms([])}
              schoolId={schoolId}
              setSchoolId={setSchoolId}
            />
          </Box>
          <Button
            variant="contained"
            color="primary"
            onClick={toggleCreateClassroomModalOpen}>
            Create Classroom
          </Button>
        </Box>
      )}

      {schoolId ? (
        <EnhancedTable
          page={page}
          setPage={setPage}
          rowsPerPage={rowsPerPage}
          setRowsPerPage={setRowsPerPage}
          filterDisabled
          selectionDisabled
          rows={classrooms}
          rowCells={rowCells}
          headCells={headCells}
          onRowClick={(classroom) => {
            setSelectedClassroom(classroom)
            toggleEditClassroomModalOpen()
          }}
        />
      ) : (
        <Box display="flex" flexDirection="column" alignItems="center">
          <Typography variant="h5" textAlign="center" mb={2}>
            Select a school to view classrooms
          </Typography>

          <Box width={400}>
            <SchoolSelector
              clear={() => setClassrooms([])}
              schoolId={schoolId}
              setSchoolId={setSchoolId}
            />
          </Box>
        </Box>
      )}

      <CreateClassroomModal
        schoolId={schoolId}
        open={createClassroomModalOpen}
        onClose={toggleCreateClassroomModalOpen}
      />

      <EditClassroomModal
        selectedClassroom={selectedClassroom}
        schoolId={schoolId}
        open={editClassroomModalOpen}
        onClose={toggleEditClassroomModalOpen}
      />

      <EditClassroomUsersModal
        selectedClassroom={selectedClassroom}
        schoolId={schoolId}
        open={editClassroomUsersModalOpen}
        onClose={toggleEditClassroomUsersModalOpen}
      />
    </Box>
  )
}

const mapDispatchToProps = {
  setSnackbar,
}

export default connect(null, mapDispatchToProps)(ClassroomPage)
