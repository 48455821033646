import React from 'react';
import { Box, Typography, Tooltip } from '@mui/material';

// map database states to display states
const stateToDisplay = {
  focused: 'focused',
  excitement: 'excited',
  curiosity: 'curious',
  frustration: 'frustrated',
  boredom: 'bored',
  confusion: 'confused'
};

const sentimentColors = {
  focused: {
    main: '#2196F3',
    light: '#E3F2FD'
  },
  excited: {
    main: '#4CAF50',
    light: '#E8F5E9'
  },
  curious: {
    main: '#9C27B0',
    light: '#F3E5F5'
  },
  frustrated: {
    main: '#F44336',
    light: '#FFEBEE'
  },
  bored: {
    main: '#9E9E9E',
    light: '#F5F5F5'
  },
  confused: {
    main: '#FF9800',
    light: '#FFF3E0'
  }
};

const StudentSentiment = ({ sentiment }) => {
    if (sentiment == "None"){
      return ''
    }
    console.log("sentiment ", sentiment)
    const displaySentiment = stateToDisplay[sentiment] || sentiment;
    console.log(displaySentiment)
    const colors = sentimentColors[displaySentiment] || sentimentColors.focused;

    // calculate font size based on text length
    const getFontSize = (text) => {
      if (text.length <= 6) return 14;
      if (text.length <= 8) return 12;
      return 11;
    };

    return (
      <Tooltip title={`Student is ${displaySentiment}`}>
        <Box sx={{ 
          display: 'flex', 
          alignItems: 'center',
        }}>
          <Box 
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: 1,
              backgroundColor: colors.light,
              padding: '4px 12px',
              borderRadius: '16px',
              width: 100,
              minWidth: 100,
              justifyContent: 'center',
            }}
          >
            <Box 
              sx={{
                width: 12,
                height: 12,
                borderRadius: '50%',
                backgroundColor: colors.main,
                flexShrink: 0,
              }}
            />
            <Typography 
              fontFamily="Inter" 
              fontSize={getFontSize(displaySentiment)}
              sx={{ 
                textTransform: 'capitalize',
                fontWeight: 500,
                whiteSpace: 'nowrap',
              }}
            >
              {displaySentiment}
            </Typography>
          </Box>
        </Box>
      </Tooltip>
    );
  };

  export default StudentSentiment;