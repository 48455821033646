import React from 'react'
import { Box, Typography } from '@mui/material'

import MaterialItem from './MaterialItem'

const EmbossedCard = ({ onClick, materials }) => (
  <Box
    onClick={onClick}
    sx={{
      backgroundColor: '#F7ECC5',
      height: '87vh',
      width: '100%',
      position: 'absolute',
      mt: 4,
      boxShadow: '3px 3px 3px 0px rgba(255, 255, 255, 0.55) inset',
      cursor: 'pointer',
    }}>
    <Box sx={{ boxShadow: '0px 0px 20px 10px #9FB8D73D', height: '100%' }}>
      <Box
        sx={{
          background: '#F7ECC5',
          width: 160,
          boxShadow: '3px 3px 3px 0px rgba(255, 255, 255, 0.55) inset',
          position: 'absolute',
          borderRadius: '8px 8px 0 0',
          top: -28,
          py: 1,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}>
        <Typography
          fontFamily="Inter"
          fontWeight="bold"
          fontSize={16}
          pl={3}
          sx={{ width: '100%' }}>
          Materials
        </Typography>
      </Box>
      <Box
        sx={{
          width: '100%',
          height: '100%',
          display: 'flex',
          alignItems: 'center',
          flexDirection: 'column',
          overflowY: 'auto',
          pt: '6vh',
          gap: 3,
          position: 'relative',
        }}>
        {materials?.map((material, index) => (
          <MaterialItem key={index} material={material} />
        ))}
      </Box>
    </Box>
  </Box>
)

export default EmbossedCard
