import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom/cjs/react-router-dom'
import { connect } from 'react-redux'
import { TextField, Box } from '@mui/material'
import { LoadingButton } from '@mui/lab'

import api from 'lib/api'
import AuthLayout from 'layouts/Auth'
import { setUser } from 'reducers/user'
import { setSnackbar } from 'reducers/ui'

const InstructorCreateAccount = (props) => {
  const history = useHistory()

  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [loading, setLoading] = useState(false)
  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')

  const handleCreateAccount = async () => {
    setLoading(true)
    try {
      const response = await api.user.auth.register({
        email,
        password,
        firstName,
        lastName,
        rolesId: 3,
      })

      props.setUser(response.data.user)
    } catch (e) {
      props.setSnackbar({ open: true, message: e.message, severity: 'error' })
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    history.push('/')
    // eslint-disable-next-line
  }, [])

  return (
    <AuthLayout
      title="Instructor Sign Up Form"
      quote={`Create your \nEllie Account`}
      quoteAuthor="">
      <Box
        sx={{
          overflow: 'auto',
          height: '100%',
          justifyContent: 'center',
          display: 'flex',
          alignItems: 'center',
          flexDirection: 'column',
        }}>
        <TextField
          variant="outlined"
          margin="normal"
          fullWidth
          id="email"
          label="Your Email"
          name="email"
          autoComplete="name"
          autoFocus
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          InputProps={{
            sx: {
              backgroundColor: 'background.paper',
            },
          }}
        />
        <TextField
          variant="outlined"
          margin="normal"
          fullWidth
          name="password"
          label="Create Password"
          type="password"
          id="password"
          autoComplete="current-password"
          InputProps={{
            sx: {
              backgroundColor: 'background.paper',
            },
          }}
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
        <TextField
          variant="outlined"
          margin="normal"
          fullWidth
          name="firstName"
          label="First Name"
          id="firstName"
          autoComplete="first-name"
          InputProps={{
            sx: {
              backgroundColor: 'background.paper',
            },
          }}
          value={firstName}
          onChange={(e) => setFirstName(e.target.value)}
        />
        <TextField
          variant="outlined"
          margin="normal"
          fullWidth
          name="lastName"
          label="Last Name"
          id="lastName"
          autoComplete="last-name"
          InputProps={{
            sx: {
              backgroundColor: 'background.paper',
            },
          }}
          value={lastName}
          onChange={(e) => setLastName(e.target.value)}
          onKeyDown={(e) => {
            if (e.key === 'Enter') handleCreateAccount()
          }}
        />

        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            marginTop: 2,
          }}>
          <Box
            sx={{
              color: 'primary.contrastText',
              my: 1,
              alignSelf: 'center',
              width: 2,
            }}></Box>
          <LoadingButton
            loading={loading}
            sx={{
              color: 'primary.contrastText',
              my: 1,
              borderRadius: 1,
            }}
            color="primary"
            variant="contained"
            onClick={handleCreateAccount}>
            Create Account
          </LoadingButton>
        </Box>
      </Box>
    </AuthLayout>
  )
}

const mapDispatchToProps = {
  setUser,
  setSnackbar,
}

export default connect(null, mapDispatchToProps)(InstructorCreateAccount)
