import { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom/cjs/react-router-dom'

import api from 'lib/api'
import { useAuth0 } from '@auth0/auth0-react'

const useCourses = (props) => {
  const { userId, role, currentSchoolId } = props
  const history = useHistory()
  const [courses, setCourses] = useState([])
  const { getAccessTokenSilently, user } = useAuth0()

  useEffect(() => {
    const fetchSchools = async () => {
      props.setLoading(true)
      try {
        const token = await getAccessTokenSilently()
        const response = await api.school.list({}, token)
        const schools = response.data.schools

        // if (schools.length !== 0) props.setCurrentSchoolId(schools[0].school.id)
      } catch (e) {
        props.setSnackbar({ open: true, message: e.message, severity: 'error' })
      } finally {
        props.setLoading(false)
      }
    }

    const fetchCourses = async (classroomTeacherId) => {
      try {
        const token = await getAccessTokenSilently()
        const response = await api.course.list({
          schoolId: currentSchoolId,
          classroomTeacherId,
        }, token)

        return response.data.courses
      } catch (e) {
        props.setSnackbar({ open: true, message: e.message, severity: 'error' })
      }
    }

    const fetchClassrooms = async () => {
      props.setLoading(true)
      try {
        const token = await getAccessTokenSilently();
        const response = await api.classroom.list({
          schoolId: currentSchoolId,
          rowsPerPage: 999,
          teachersPerPage: 99999,
          pupilsPerPage: 99999,
          mine: role === 'teacher',
        }, token);
        const classrooms = response.data.classrooms

        if (classrooms.length > 0) {
          let allCourses = await Promise.all(
            classrooms
              .filter((classroom) =>
                role === 'teacher'
                  ? classroom.teachers?.find((teacher) => teacher.id === userId)
                      ?.classroomTeacherId
                  : classroom.pupils.find((pupil) => pupil.id === userId)
              )
              .map(async (classroom) => {
                const filteredCourses = await fetchCourses(
                  role === 'teacher'
                    ? classroom.teachers?.find((t) => t.id === userId)
                        ?.classroomTeacherId
                    : classroom.teachers[0].classroomTeacherId
                )

                if (!filteredCourses) return

                return filteredCourses?.map((course) => ({
                  ...course.course,
                  classroomId: classroom.classroom.id,
                }))
              })
          )

          allCourses = allCourses.flat(1).filter((c) => c)

          if (allCourses.length > 0) setCourses(allCourses)
          else history.push('/classes')
        } else history.push('/classes')
      } catch (e) {
        props.setSnackbar({ open: true, message: e.message, severity: 'error' })
      } finally {
        props.setLoading(false)
      }
    }

    if (currentSchoolId) fetchClassrooms()
    else fetchSchools()

    props.setSelectedCourse()
    props.setSelectedActivity()
    props.setCurrentClassroomTeacherId()
    props.setCurrentClassroomId()
    // eslint-disable-next-line
  }, [currentSchoolId, getAccessTokenSilently, user])

  return courses
}

export { useCourses }
