import React, { useState } from 'react'
import { connect } from 'react-redux'
import { TextField, Link, Box } from '@mui/material'
import { LoadingButton } from '@mui/lab'

import api from 'lib/api'
import AuthLayout from 'layouts/Auth'
import { setUser, setAdmin } from 'reducers/user'
import { setSnackbar } from 'reducers/ui'

const LoginPage = (props) => {
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [loading, setLoading] = useState(false)

  const handleLogin = async () => {
    setLoading(true)
    try {
      const response = await api.admin.auth.login({ email, password })
      props.setAdmin({
        ...response.data.admin,
        role: 'super_admin',
      })
    } catch (e) {
      props.setSnackbar({ open: true, message: e.message, severity: 'error' })
    } finally {
      setLoading(false)
    }
  }

  return (
    <AuthLayout
      title="Admin Login"
      quote={`“We are what we repeatedly do. Excellence, therefore, is not an act,
    but a habit.”`}
      quoteAuthor="Aristotole">
      <Box>
        <TextField
          variant="outlined"
          margin="normal"
          fullWidth
          id="email"
          label="Email"
          name="email"
          autoComplete="email"
          autoFocus
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          InputProps={{
            sx: {
              backgroundColor: 'background.paper',
            },
          }}
        />
        <TextField
          variant="outlined"
          margin="normal"
          fullWidth
          name="password"
          label="Password"
          type="password"
          id="password"
          autoComplete="current-password"
          InputProps={{
            sx: {
              backgroundColor: 'background.paper',
            },
          }}
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          onKeyDown={(e) => {
            if (e.key === 'Enter') handleLogin()
          }}
        />
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-end',
            // justifyContent: 'space-between', // use for when forgot password link is visible
            alignItems: 'center',
            marginTop: 2,
          }}>
          {/* <Link
            href="/forgot-password"
            variant="body2"
            fontWeight="bold"
            sx={{ weight: 1, textDecoration: 'none' }}>
            Forgot Password?
          </Link> */}
          <LoadingButton
            loading={loading}
            sx={{
              color: 'primary.contrastText',
              my: 1,
              borderRadius: 1,
            }}
            color="primary"
            variant="contained"
            onClick={handleLogin}>
            Login
          </LoadingButton>
        </Box>
      </Box>
    </AuthLayout>
  )
}

const mapDispatchToProps = {
  setUser,
  setAdmin,
  setSnackbar,
}

export default connect(null, mapDispatchToProps)(LoginPage)
