import React, { useState } from 'react'
import { connect } from 'react-redux'
import { Box, TextField } from '@mui/material'

import api from 'lib/api'
import { setSnackbar } from 'reducers/ui'
import Modal from 'components/Modal'
import PlanSelector from 'components/selectors/PlanSelector'
import { useAuth0 } from '@auth0/auth0-react'

const CreateSchoolModal = (props) => {
  const { open, onClose } = props
  const { getAccessTokenSilently } = useAuth0()

  const [name, setName] = useState('')
  const [address, setAddress] = useState('')
  const [city, setCity] = useState('')
  const [state, setState] = useState('')
  const [zipCode, setZipCode] = useState('')
  const [contactEmail, setContactEmail] = useState('')
  const [contactPhoneNumber, setContactPhoneNumber] = useState('')
  const [planId, setPlanId] = useState()

  const onSubmit = async () => {
    try {
      const token = await getAccessTokenSilently()
      await api.school.create({
        name,
        address,
        city,
        state,
        zipCode,
        contactEmail,
        contactPhoneNumber,
        planId,
      }, token)

      handleClose()
    } catch (error) {
      props.setSnackbar({
        open: true,
        severity: 'error',
        message: error.message,
      })
    }
  }

  const handleClose = () => {
    setName('')
    setAddress('')
    setCity('')
    setState('')
    setZipCode('')
    setContactEmail('')
    setContactPhoneNumber('')
    setPlanId()

    onClose()
  }

  return (
    <Modal
      open={open}
      onClose={handleClose}
      onSubmit={onSubmit}
      title="Create School"
      submitText="Create"
      cancelText="Cancel">
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: 2,
          width: 400,
          padding: 2,
        }}>
        <TextField
          fullWidth
          label="Name"
          type="text"
          autoComplete="organization"
          value={name}
          onChange={(e) => setName(e.target.value)}
        />
        <TextField
          fullWidth
          label="Address"
          type="text"
          autoComplete="street-address"
          value={address}
          onChange={(e) => setAddress(e.target.value)}
        />
        <TextField
          fullWidth
          label="City"
          value={city}
          onChange={(e) => setCity(e.target.value)}
        />
        <TextField
          fullWidth
          label="State"
          value={state}
          onChange={(e) => setState(e.target.value)}
        />
        <TextField
          fullWidth
          label="ZIP Code"
          type="number"
          autoComplete="postal-code"
          value={zipCode}
          onChange={(e) => setZipCode(e.target.value)}
        />
        <TextField
          fullWidth
          label="Contact Email"
          type="email"
          autoComplete="email"
          value={contactEmail}
          onChange={(e) => setContactEmail(e.target.value)}
        />
        <TextField
          fullWidth
          label="Contact Phone Number"
          type="tel"
          autoComplete="tel"
          value={contactPhoneNumber}
          onChange={(e) => setContactPhoneNumber(e.target.value)}
        />

        <PlanSelector planId={planId} setPlanId={setPlanId} />
      </Box>
    </Modal>
  )
}

const mapDispatchToProps = {
  setSnackbar,
}

export default connect(null, mapDispatchToProps)(CreateSchoolModal)
