import React from 'react'
import { connect } from 'react-redux'
import { Box, Typography } from '@mui/material'

import ObjectiveProgressIndicator from './ObjectiveProgressIndicator'
import Avatar from 'components/Avatar'
import StudentSentiment from './StudentSentiment'

const StudentItem = (props) => {
  const { selectedActivity, student, showNotes } = props

  const onClick = () => {
    props.setSelectedStudent(student)
    props.toggleStudentNotesModalOpen()
  }

  const findMaxEmotion = (student) => {
    //const emotions = ['focused', 'excitement', 'curiosity', 'confusion', 'frustration', 'boredom'];
    if (student.sentiment_analytics && student.sentiment_analytics[0]){
      let max_val = 0
      const sentiments = student.sentiment_analytics[0]
      let sentiment = 'None'
      if (sentiments.focused > max_val){
        max_val = sentiments.focused
        sentiment = 'focused'
      }
      if (sentiments.excitement > max_val){
        max_val = sentiments.excitement
        sentiment = 'excitement'
      }
      if (sentiments.curiosity > max_val){
        max_val = sentiments.curiosity
        sentiment = 'curiosity'
      }
      if (sentiments.confusion > max_val){
        max_val = sentiments.confusion
        sentiment = 'confusion'
      }
      if (sentiments.frustration > max_val){
        max_val = sentiments.frustration
        sentiment = 'frustration'
      }
      if (sentiments.boredom > max_val){
        max_val = sentiments.boredom
        sentiment = 'boredom'
      }
      return sentiment
    }else{
      return 'None'
    }

  }
  
  return (
    <Box
      onClick={onClick}
      sx={{
        gap: 2,
        display: 'flex',
        alignItems: 'center',
        cursor: 'pointer',
        width: '100%',
      }}>
      <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, flex: 2 }}>
        <Avatar
          selectedUser={student}
          sx={{
            width: 50,
            height: 50,
            fontSize: 18,
          }}
        />
        <Typography fontFamily="Inter" fontSize={14}>
          {student?.name}
        </Typography>
      </Box>
      {selectedActivity && (
        <>
          <Box sx={{ flex: 1 }}>
            <StudentSentiment sentiment={findMaxEmotion(student)} />
          </Box>
          <Box
            sx={{
              flex: 1,
              display: 'flex',
              gap: 1,
              alignItems: 'center',
            }}>
            {student?.analytics?.map((analysis, index) => (
              <ObjectiveProgressIndicator key={index} analysis={analysis} />
            ))}
          </Box>
        </>
      )}
      {/* {showNotes && (
        <Typography fontFamily="Inter" fontSize={14} sx={{ flex: 1, overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
          {student?.notes}
        </Typography>
      )} */}
    </Box>
  )
}

const mapStateToProps = (state) => ({
  selectedActivity: state.user.selectedActivity,
})

export default connect(mapStateToProps)(StudentItem)
