import React from 'react'
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from '@mui/material'

const Modal = (props) => {
  const { open, onClose, onSubmit, onDelete, disabled } = props

  const confirmDelete = () => {
    if (window.confirm('Are you sure you want to delete this item?')) {
      onDelete()
      onClose()
    }
  }

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle fontFamily="Inter" fontSize={24} fontWeight="bold">
        {props.title}
      </DialogTitle>
      <DialogContent>{props.children}</DialogContent>
      <DialogActions sx={{ justifyContent: 'space-between' }}>
        <Box>
          <Button onClick={onClose}>
            <Typography fontFamily="Inter" fontSize={14} fontWeight="bold">
              {props.cancelText}
            </Typography>
          </Button>
        </Box>
        <Box display="flex" flexDirection="row" gap={1}>
          {onDelete && (
            <Button variant="contained" color="error" onClick={confirmDelete}>
              <Typography fontFamily="Inter" fontSize={14} fontWeight="bold">
                Delete
              </Typography>
            </Button>
          )}
          {onSubmit && (
            <Button variant="contained" disabled={disabled} onClick={onSubmit}>
              <Typography fontFamily="Inter" fontSize={14} fontWeight="bold">
                {props.submitText}
              </Typography>
            </Button>
          )}
        </Box>
      </DialogActions>
    </Dialog>
  )
}

export default Modal
