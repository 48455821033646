import React, { useState } from 'react'
import { connect } from 'react-redux'
import { Box, Button, IconButton, TextField } from '@mui/material'
import { DatePicker } from '@mui/x-date-pickers'
import { Delete } from '@mui/icons-material'
import moment from 'moment'

import api from 'lib/api'
import { setSnackbar } from 'reducers/ui'
import Modal from 'components/Modal'
import ClassroomTeacherSelector from 'components/selectors/ClassroomTeacherSelector'
import { useAuth0 } from '@auth0/auth0-react'

const CreateClassroomModal = (props) => {
  const { schoolId, open, onClose } = props
  const { getAccessTokenSilently } = useAuth0()

  const [name, setName] = useState('')
  const [startDate, setStartDate] = useState()
  const [endDate, setEndDate] = useState()
  const [maxPupils, setMaxPupils] = useState('')
  const [teachers, setTeachers] = useState([
    {
      subject: '',
    },
  ])

  const onSubmit = async () => {
    try {
      const token = await getAccessTokenSilently()
      await api.classroom.create({
        schoolId,
        name,
        startDate: moment(startDate).format('YYYY-MM-DD hh:mm:ss'),
        endDate: moment(endDate).format('YYYY-MM-DD hh:mm:ss'),
        maxPupils: parseInt(maxPupils),
        teachers,
      }, token)

      handleClose()
    } catch (error) {
      props.setSnackbar({
        open: true,
        severity: 'error',
        message: error.message,
      })
    }
  }

  const handleClose = () => {
    setName('')
    setStartDate()
    setEndDate()
    setMaxPupils('')
    setTeachers([
      {
        subject: '',
      },
    ])

    onClose()
  }

  const addTeacher = () => {
    setTeachers([
      ...teachers,
      {
        teachersId: null,
        subject: '',
      },
    ])
  }

  const removeTeacher = (index) => {
    setTeachers(teachers.filter((_, i) => i !== index))
  }

  const updateTeacher = (index, field, value) => {
    const updatedTeachers = teachers.map((teacher, i) =>
      i === index ? { ...teacher, [field]: value } : teacher
    )
    setTeachers(updatedTeachers)
  }

  return (
    <Modal
      open={open}
      onClose={handleClose}
      onSubmit={onSubmit}
      title="Create Classroom"
      submitText="Create"
      cancelText="Cancel">
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: 2,
          width: 400,
          padding: 2,
        }}>
        <TextField
          fullWidth
          label="Name"
          value={name}
          onChange={(e) => setName(e.target.value)}
        />
        <DatePicker
          label="Start Date"
          value={startDate}
          onChange={(date) => setStartDate(date)}
        />
        <DatePicker
          label="End Date"
          value={endDate}
          onChange={(date) => setEndDate(date)}
        />
        <TextField
          fullWidth
          label="Max Pupils"
          value={maxPupils}
          type="number"
          onChange={(e) => setMaxPupils(e.target.value)}
        />
        {teachers.map((teacher, index) => (
          <Box key={index} display="flex" alignItems="center">
            <Box display="flex" flexDirection="column" gap={2} flex={1}>
              <ClassroomTeacherSelector
                schoolId={schoolId}
                classroomTeacherId={teacher.teachersId}
                setClassroomTeacherId={(value) =>
                  updateTeacher(index, 'teachersId', value)
                }
              />
              <TextField
                fullWidth
                label="Subject"
                value={teacher.subject}
                onChange={(e) =>
                  updateTeacher(index, 'subject', e.target.value)
                }
              />
            </Box>
            {teachers.length > 1 && (
              <IconButton onClick={() => removeTeacher(index)}>
                <Delete />
              </IconButton>
            )}
          </Box>
        ))}
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-end',
          }}>
          <Button onClick={addTeacher}>+ Add another teacher</Button>
        </Box>
      </Box>
    </Modal>
  )
}

const mapDispatchToProps = {
  setSnackbar,
}

export default connect(null, mapDispatchToProps)(CreateClassroomModal)
