import React, { useState } from 'react'
import { connect } from 'react-redux'
import { useHistory } from 'react-router-dom'
import {
  Box,
  Button,
  Grid,
  Typography,
  TextField,
  CircularProgress,
} from '@mui/material'
import { Add, ArrowForward } from '@mui/icons-material'
import { setSnackbar } from 'reducers/ui'
import {
  setCurrentClassroomId,
  setCurrentClassroomTeacherId,
  setCurrentSchoolId,
} from 'reducers/user'
import api from 'lib/api'

const GetStartedWithCode = (props) => {
  const { role, firstName, userId, currentSchoolId } = props
  const history = useHistory()
  const [signupCode, setSignupCode] = useState('')
  const [enrolling, setEnrolling] = useState(false)

  const handleSubmitCode = async () => {
    setEnrolling(true)
    try {
      const response = await api.classroom.enroll({
        schoolId: currentSchoolId,
        classroomId: signupCode,
        userId,
      })
      props.setSnackbar({
        open: true,
        message: 'Successfully enrolled in the class!',
        severity: 'success',
      })

      window.location.reload()
    } catch (error) {
      console.error('Enrollment error:', error)
      props.setSnackbar({
        open: true,
        message:
          error.response?.data?.message ||
          error.message ||
          'Failed to enroll in the class',
        severity: 'error',
      })
    } finally {
      setEnrolling(false)
    }
  }

  return (
    <Grid maxWidth container>
      <Grid item xs={12} sx={{ position: 'relative' }}>
        <Box
          sx={{
            width: '100%',
            height: '90vh',
            maxHeight: '90vh',
            overflowY: 'auto',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
          }}>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'start',
              alignItems: 'start',
              flexDirection: 'column',
              gap: 4,
              width: '40%',
              p: 2,
            }}>
            <Typography
              variant="h1"
              fontWeight="400"
              fontFamily="Besley"
              fontSize={36}
              textAlign="start">
              Welcome {firstName}
            </Typography>
            <Box sx={{ width: '100%' }}>
              <Typography
                fontFamily="Besley"
                fontSize="18px"
                fontWeight="400"
                lineHeight="24.3px"
                letterSpacing="0.01em"
                textAlign="left"
                sx={{ mb: 3 }}>
                Welcome to the Ellie Classroom Assistant!{' '}
                <strong>
                  Please enter your sign-up code below to get started...
                </strong>
              </Typography>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: 2,
                  width: '100%',
                  maxWidth: '400px',
                }}>
                <TextField
                  fullWidth
                  label="Sign-up Code"
                  variant="outlined"
                  value={signupCode}
                  onChange={(e) => setSignupCode(e.target.value)}
                  disabled={enrolling}
                  sx={{
                    '& .MuiOutlinedInput-root': {
                      fontFamily: 'Inter',
                    },
                    '& .MuiInputLabel-root': {
                      fontFamily: 'Inter',
                    },
                  }}
                />
                <Button
                  variant="contained"
                  endIcon={
                    enrolling ? (
                      <CircularProgress size={20} />
                    ) : (
                      <ArrowForward />
                    )
                  }
                  onClick={handleSubmitCode}
                  disabled={enrolling || !signupCode}
                  sx={{
                    alignSelf: 'flex-start',
                    fontFamily: 'Inter',
                  }}>
                  {enrolling ? 'Enrolling...' : 'Submit Code'}
                </Button>
              </Box>
            </Box>
          </Box>
        </Box>
      </Grid>
    </Grid>
  )
}

const mapStateToProps = (state) => ({
  userId: state.user.id,
  role: state.user.role,
  firstName: state.user.firstName,
  currentSchoolId: state.user.currentSchoolId,
})

const mapDispatchToProps = {
  setCurrentClassroomId,
  setCurrentClassroomTeacherId,
  setCurrentSchoolId,
  setSnackbar,
}

export default connect(mapStateToProps, mapDispatchToProps)(GetStartedWithCode)
