import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { Box, FormControl, Select, MenuItem, Typography } from '@mui/material'

import api from 'lib/api'
import { setSnackbar } from 'reducers/ui'
import { useAuth0 } from '@auth0/auth0-react'

const LlmRoleSelector = (props) => {
  const { getAccessTokenSilently, user } = useAuth0()
  const { llmRolesId, setLlmRolesId } = props

  const [llmRoles, setLlmRoles] = useState([])

  const handleChange = (event) => {
    setLlmRolesId(event.target.value)
  }

  useEffect(() => {
    const fetchLlmRoles = async () => {
      try {
        const token = await getAccessTokenSilently()
        const response = await api.llmRoles.list({}, token)
        setLlmRoles(response.data.llmRoles)
      } catch (e) {
        props.setSnackbar({
          open: true,
          severity: 'error',
          message: e.message,
        })
      }
    }

    fetchLlmRoles()
    // eslint-disable-next-line
  }, [])

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: '8px',
        marginBottom: 2,
      }}>
      <Typography
        sx={{
          fontFamily: 'Inter',
          fontSize: '16px',
          fontWeight: 600,
          lineHeight: '19.36px',
          letterSpacing: '0.02em',
          textAlign: 'left',
          display: 'flex',
          marginBottom: '8px',
        }}>
        Ellie's Role
        <Typography
          sx={{
            color: 'primary.main',
            fontSize: '12px',
            lineHeight: '14.63px',
            letterSpacing: '0.02em',
            ml: 1,
          }}>
          *
        </Typography>
      </Typography>
      <FormControl fullWidth>
        <Select
          labelId="role-selector-label"
          id="role-selector"
          value={llmRolesId}
          onChange={handleChange}
          sx={{
            backgroundColor: 'white',
            borderRadius: 2,
            '.MuiOutlinedInput-notchedOutline': {
              borderColor: 'white',
            },
            '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
              borderColor: 'white',
            },
            '&:hover .MuiOutlinedInput-notchedOutline': {
              borderColor: 'white',
            },
          }}>
          {llmRoles &&
            llmRoles.map((llmRole, index) => (
              <MenuItem key={index + 1} value={llmRole.id}>
                {llmRole.name}
              </MenuItem>
            ))}
        </Select>
      </FormControl>
    </Box>
  )
}

const mapStateToProps = (state) => ({
  user: state.user,
})

const mapDispatchToProps = {
  setSnackbar,
}

export default connect(mapStateToProps, mapDispatchToProps)(LlmRoleSelector)
