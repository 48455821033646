import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { Box, Button, TableCell, Typography } from '@mui/material'

import api from 'lib/api'
import { setSnackbar } from 'reducers/ui'
import EnhancedTable from 'components/DataTable'
import SchoolSelector from 'components/selectors/SchoolSelector'
import InviteTeacherModal from 'components/modals/InviteTeacherModal'
import CreateUserModal from 'components/modals/CreateUserModal'
import EditUserModal from 'components/modals/EditUserModal'
import { useAuth0 } from '@auth0/auth0-react'

const TeacherPage = (props) => {
  const { master, role } = props
  const { getAccessTokenSilently } = useAuth0()

  const [schoolId, setSchoolId] = useState()
  const [teachers, setTeachers] = useState([])
  const [inviteTeacherModalOpen, setInviteTeacherModalOpen] = useState(false)
  const [editTeacherModalOpen, setEditTeacherModalOpen] = useState(false)
  const [createUserModalOpen, setCreateUserModalOpen] = useState(false)
  const [inviteToken, setInviteToken] = useState(false)
  const [selectedTeacher, setSelectedTeacher] = useState()

  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(10)

  const toggleInviteTeacherModalOpen = () =>
    setInviteTeacherModalOpen(!inviteTeacherModalOpen)

  const toggleEditTeacherModalOpen = () =>
    setEditTeacherModalOpen(!editTeacherModalOpen)

  const toggleCreateUserModalOpen = () =>
    setCreateUserModalOpen(!createUserModalOpen)

  const rowCells = (row) => (
    <>
      <TableCell align="left">{row.id}</TableCell>
      <TableCell align="right">{row.email}</TableCell>
      <TableCell align="right">{row.firstName}</TableCell>
      <TableCell align="right">{row.lastName}</TableCell>
      <TableCell align="right">{row.isActive ? 'YES' : 'NO'}</TableCell>
    </>
  )

  const headCells = [
    {
      id: 'id',
      numeric: false,
      disablePadding: false,
      label: 'ID',
    },
    {
      id: 'email',
      numeric: true,
      disablePadding: false,
      label: 'Email',
    },
    {
      id: 'firstName',
      numeric: true,
      disablePadding: false,
      label: 'First Name',
    },
    {
      id: 'lastName',
      numeric: true,
      disablePadding: false,
      label: 'Last Name',
    },
    {
      id: 'isActive',
      numeric: true,
      disablePadding: false,
      label: 'Active',
    },
  ]

  useEffect(() => {
    const fetchInviteTokens = async () => {
      try {
        const token = await getAccessTokenSilently()
        const response = await api.invite.list({
          schoolId,
        }, token)

        const schoolInviteToken = response.data?.schoolInvites?.filter(
          (schoolInvite) => schoolInvite.usedAt === null
        )[0]?.token

        if (schoolInviteToken && role === 'school_admin')
          setInviteToken(schoolInviteToken)
        else setInviteToken(false)
      } catch (error) {
        props.setSnackbar({
          open: true,
          message: `Error fetching teachers: ${error.message}`,
          severity: 'error',
        })
      }
    }

    const fetchTeachers = async () => {
      try {
        const token = await getAccessTokenSilently()
        const data = { roleId: 3, page: page + 1, rowsPerPage }
        if (schoolId) {
          data.schoolId = schoolId
        }
        const response = await api.user.list(data, token)
        if (response.data?.users?.length === 0 && page > 0)
          setPage((page) => page - 1)
        else if (
          response.data?.users?.length > 0 &&
          !(teachers.length > rowsPerPage * page)
        )
          if (page > 0)
            setTeachers((teachers) =>
              [
                ...teachers,
                ...response.data.users.map((item) => item.user),
              ].filter(
                (value, index, self) =>
                  index === self.findIndex((t) => t.id === value.id)
              )
            )
          else setTeachers(response.data.users.map((item) => item.user))
        else if (response.data?.users?.length > 0 && page === 0)
          setTeachers(response.data.users.map((item) => item.user))
      } catch (error) {
        props.setSnackbar({
          open: true,
          message: `Error fetching teachers: ${error.message}`,
          severity: 'error',
        })
      }
    }

    if (!schoolId) setInviteToken(false)

    if (
      !editTeacherModalOpen &&
      !createUserModalOpen &&
      !inviteTeacherModalOpen
    )
      fetchTeachers()
    if (
      !editTeacherModalOpen &&
      !createUserModalOpen &&
      !inviteTeacherModalOpen &&
      schoolId
    )
      fetchInviteTokens()
    // eslint-disable-next-line
  }, [
    schoolId,
    editTeacherModalOpen,
    createUserModalOpen,
    inviteTeacherModalOpen,
    page,
    rowsPerPage,
  ])

  return (
    <Box>
      <Typography variant="h4" textAlign="center" mb={2}>
        Teachers
      </Typography>

      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          marginBottom: 2,
        }}>
        <Box width={400}>
          <SchoolSelector
            clear={() => setTeachers([])}
            allowAll
            schoolId={schoolId}
            setSchoolId={setSchoolId}
          />
        </Box>
        <Box display="flex" gap={2}>
          {inviteToken && (
            <Button
              variant="contained"
              color="primary"
              onClick={toggleCreateUserModalOpen}>
              Create Teacher
            </Button>
          )}
          <Button
            variant="contained"
            color="primary"
            onClick={toggleInviteTeacherModalOpen}>
            Invite Teacher
          </Button>
        </Box>
      </Box>

      <EnhancedTable
        page={page}
        setPage={setPage}
        rowsPerPage={rowsPerPage}
        setRowsPerPage={setRowsPerPage}
        filterDisabled
        selectionDisabled
        rows={teachers}
        rowCells={rowCells}
        headCells={headCells}
        onRowClick={(teacher) => {
          if (master === true) {
            setSelectedTeacher(teacher)
            toggleEditTeacherModalOpen()
          }
        }}
      />

      <InviteTeacherModal
        schoolId={schoolId}
        setSchoolId={setSchoolId}
        open={inviteTeacherModalOpen}
        onClose={toggleInviteTeacherModalOpen}
      />

      <EditUserModal
        selectedUser={selectedTeacher}
        open={editTeacherModalOpen}
        onClose={toggleEditTeacherModalOpen}
      />

      <CreateUserModal
        rolesId={3}
        inviteToken={inviteToken}
        open={createUserModalOpen}
        onClose={toggleCreateUserModalOpen}
      />
    </Box>
  )
}

const mapStateToProps = (state) => ({
  master: state.user.master,
  role: state.user.role,
})

const mapDispatchToProps = {
  setSnackbar,
}

export default connect(mapStateToProps, mapDispatchToProps)(TeacherPage)
