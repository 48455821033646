import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { Box, Button, TableCell, Typography } from '@mui/material'

import api from 'lib/api'
import { setSnackbar } from 'reducers/ui'
import EnhancedTable from 'components/DataTable'
import SchoolSelector from 'components/selectors/SchoolSelector'
import CreateUserModal from 'components/modals/CreateUserModal'
import EditUserModal from 'components/modals/EditUserModal'
import { useAuth0 } from '@auth0/auth0-react'

const StudentPage = (props) => {
  const { master, role } = props
  const { getAccessTokenSilently } = useAuth0()

  const [schoolId, setSchoolId] = useState()
  const [students, setStudents] = useState([])
  const [editStudentModalOpen, setEditStudentModalOpen] = useState(false)
  const [createUserModalOpen, setCreateUserModalOpen] = useState(false)
  const [schoolCode, setSchoolCode] = useState(false)
  const [selectedStudent, setSelectedStudent] = useState()

  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(10)

  const toggleEditStudentModalOpen = () =>
    setEditStudentModalOpen(!editStudentModalOpen)

  const toggleCreateUserModalOpen = () =>
    setCreateUserModalOpen(!createUserModalOpen)

  const handleGenerateSchoolCode = async () => {
    try {
      const token = await getAccessTokenSilently()
      const response = await api.school.generateCode({ schoolId }, token)
      if (response?.data?.code && role === 'school_admin')
        setSchoolCode(response.data.code)
    } catch (error) {
      props.setSnackbar({
        open: true,
        message: `Error fetching teachers: ${error.message}`,
        severity: 'error',
      })
    }
  }

  const rowCells = (row) => (
    <>
      <TableCell align="left">{row.id}</TableCell>
      <TableCell align="right">
        {row.schools.map((school) => school.id)}
      </TableCell>
      <TableCell align="right">{row.email}</TableCell>
      <TableCell align="right">{row.firstName}</TableCell>
      <TableCell align="right">{row.lastName}</TableCell>
      <TableCell align="right">{row.isActive ? 'YES' : 'NO'}</TableCell>
    </>
  )

  const headCells = [
    {
      id: 'id',
      numeric: false,
      disablePadding: false,
      label: 'ID',
    },
    {
      id: 'schoolId',
      numeric: true,
      disablePadding: false,
      label: 'School ID',
    },
    {
      id: 'email',
      numeric: true,
      disablePadding: false,
      label: 'Email',
    },
    {
      id: 'firstName',
      numeric: true,
      disablePadding: false,
      label: 'First Name',
    },
    {
      id: 'lastName',
      numeric: true,
      disablePadding: false,
      label: 'Last Name',
    },
    {
      id: 'isActive',
      numeric: true,
      disablePadding: false,
      label: 'Active',
    },
  ]

  useEffect(() => {
    const fetchSchoolCodes = async () => {
      try {
        const token = await getAccessTokenSilently()
        const response = await api.school.listCodes({
          schoolId,
        }, token)

        const code = response.data?.codes?.filter((code) => code.isActive)[0]
          ?.code

        if (code && role === 'school_admin') setSchoolCode(code)
        else setSchoolCode(false)
      } catch (error) {
        props.setSnackbar({
          open: true,
          message: `Error fetching teachers: ${error.message}`,
          severity: 'error',
        })
      }
    }

    const fetchStudents = async () => {
      try {
        const token = await getAccessTokenSilently()
        const data = { roleId: 4, page: page + 1, rowsPerPage }
        if (schoolId) {
          data.schoolId = schoolId
        }
        const response = await api.user.list(data, token)
        if (response.data?.users?.length === 0 && page > 0)
          setPage((page) => page - 1)
        else if (
          response.data?.users?.length > 0 &&
          !(students.length > rowsPerPage * page)
        )
          if (page > 0)
            setStudents((students) =>
              [
                ...students,
                ...response.data.users.map((item) => ({
                  ...item.user,
                  schools: item.schools,
                })),
              ].filter(
                (value, index, self) =>
                  index === self.findIndex((t) => t.id === value.id)
              )
            )
          else
            setStudents(
              response.data.users.map((item) => ({
                ...item.user,
                schools: item.schools,
              }))
            )
        else if (response.data?.users?.length > 0 && page === 0)
          setStudents(
            response.data.users.map((item) => ({
              ...item.user,
              schools: item.schools,
            }))
          )
      } catch (error) {
        props.setSnackbar({
          open: true,
          message: `Error fetching students: ${error.message}`,
          severity: 'error',
        })
      }
    }

    if (!schoolId) setSchoolCode(false)

    if (!editStudentModalOpen && !createUserModalOpen) fetchStudents()
    if (!editStudentModalOpen && !createUserModalOpen && schoolId)
      fetchSchoolCodes()
    // eslint-disable-next-line
  }, [schoolId, editStudentModalOpen, createUserModalOpen, page, rowsPerPage])

  return (
    <Box>
      <Typography variant="h4" textAlign="center" mb={2}>
        Students
      </Typography>

      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          marginBottom: 2,
        }}>
        <Box width={400}>
          <SchoolSelector
            clear={() => setStudents([])}
            allowAll
            schoolId={schoolId}
            setSchoolId={setSchoolId}
          />
        </Box>
        {schoolCode && (
          <Button
            variant="contained"
            color="primary"
            onClick={toggleCreateUserModalOpen}>
            Create Student
          </Button>
        )}
        {schoolId && !schoolCode && (
          <Button
            variant="contained"
            color="primary"
            onClick={handleGenerateSchoolCode}>
            Generate School Code
          </Button>
        )}
      </Box>

      <EnhancedTable
        page={page}
        setPage={setPage}
        rowsPerPage={rowsPerPage}
        setRowsPerPage={setRowsPerPage}
        filterDisabled
        selectionDisabled
        rows={students}
        rowCells={rowCells}
        headCells={headCells}
        onRowClick={(student) => {
          if (master === true) {
            setSelectedStudent(student)
            toggleEditStudentModalOpen()
          }
        }}
      />

      <EditUserModal
        selectedUser={selectedStudent}
        open={editStudentModalOpen}
        onClose={toggleEditStudentModalOpen}
      />

      <CreateUserModal
        rolesId={4}
        code={schoolCode}
        open={createUserModalOpen}
        onClose={toggleCreateUserModalOpen}
      />
    </Box>
  )
}

const mapStateToProps = (state) => ({
  master: state.user.master,
  role: state.user.role,
})

const mapDispatchToProps = {
  setSnackbar,
}

export default connect(mapStateToProps, mapDispatchToProps)(StudentPage)
