import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { Box, TextField } from '@mui/material'
import { DatePicker } from '@mui/x-date-pickers'
import moment from 'moment'

import api from 'lib/api'
import { setSnackbar } from 'reducers/ui'
import Modal from 'components/Modal'

const EditClassroomModal = (props) => {
  const { selectedClassroom, schoolId, open, onClose } = props

  const [name, setName] = useState('')
  const [startDate, setStartDate] = useState()
  const [endDate, setEndDate] = useState()
  const [maxPupils, setMaxPupils] = useState('')

  const onSubmit = async () => {
    try {
      await api.classroom.update({
        schoolId,
        classroomId: selectedClassroom.id,
        name,
        startDate: moment(startDate).format('YYYY-MM-DD hh:mm:ss'),
        endDate: moment(endDate).format('YYYY-MM-DD hh:mm:ss'),
        maxPupils: parseInt(maxPupils),
      })

      setName('')
      setStartDate()
      setEndDate()
      setMaxPupils('')

      onClose()
    } catch (error) {
      props.setSnackbar({
        open: true,
        severity: 'error',
        message: error.message,
      })
    }
  }

  useEffect(() => {
    setName(selectedClassroom?.name || '')
    setStartDate(
      selectedClassroom?.startDate
        ? moment(selectedClassroom?.startDate)
        : moment()
    )
    setEndDate(
      selectedClassroom?.endDate ? moment(selectedClassroom?.endDate) : moment()
    )
    setMaxPupils(selectedClassroom?.maxPupils || '')
  }, [selectedClassroom])

  return (
    <Modal
      open={open}
      onClose={onClose}
      onSubmit={onSubmit}
      title="Edit Classroom"
      submitText="Save"
      cancelText="Cancel">
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: 2,
          width: 400,
          padding: 2,
        }}>
        <TextField
          fullWidth
          label="Name"
          value={name}
          onChange={(e) => setName(e.target.value)}
        />
        <DatePicker
          label="Start Date"
          value={startDate}
          onChange={(date) => setStartDate(date)}
        />
        <DatePicker
          label="End Date"
          value={endDate}
          onChange={(date) => setEndDate(date)}
        />
        <TextField
          fullWidth
          label="Max Pupils"
          value={maxPupils}
          type="number"
          onChange={(e) => setMaxPupils(e.target.value)}
        />
      </Box>
    </Modal>
  )
}

const mapDispatchToProps = {
  setSnackbar,
}

export default connect(null, mapDispatchToProps)(EditClassroomModal)
