import React from 'react'
import { Box, Typography } from '@mui/material'

import { ReactComponent as SystemUserIcon } from 'assets/systemuserchaticon.svg'

const MessageComponent = ({ message, isReceived = false }) => (
  <Box sx={{ display: 'flex', gap: 2, justifyContent: 'center', mb: 2 }}>
    {isReceived && (
      <Box sx={{ mt: 1 }}>
        <SystemUserIcon width={32} height={32} />
      </Box>
    )}
    {!isReceived && (
      <Box sx={{ mt: 1 }}>
        <Box width={32} height={32} />
      </Box>
    )}
    <Box
      sx={{
        width: '85%',
        minHeight: 60,
        p: 2,
        backgroundColor: isReceived ? 'messages.received' : 'messages.sent',
        borderRadius: 2,
        display: 'flex',
        justifyContent: 'start',
        alignItems: 'center',
      }}>
      <Typography
        whiteSpace="pre-line"
        lineHeight={1}
        fontFamily="Inter"
        fontWeight={400}
        fontSize={16}>
        {message}
      </Typography>
    </Box>
  </Box>
)

export default MessageComponent
