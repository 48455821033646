import React from 'react'
import { useHistory } from 'react-router-dom/cjs/react-router-dom'
import { Button, Grid, Typography } from '@mui/material'

import AuthLayout from 'layouts/Auth'
import { useAuth0 } from '@auth0/auth0-react';

const Landing = () => {
  const history = useHistory()
  const { loginWithRedirect } = useAuth0();

  const handleLogin = async () => {
    try {
      await loginWithRedirect({
        authorizationParams: {
          prompt: 'login',
          // max_age: 0
        }
      })
    } catch (error) {
      console.error('Login error:', error)
    }
  }

  const handleSignup = () => {
    try {
      loginWithRedirect({
        authorizationParams: {
          screen_hint: "signup",
          prompt: "login",
          max_age: 0
        },
        extraParams: {
          clearSession: true
        }
      })
    } catch (error) {
      console.error('Signup error:', error)
    }
  }

  return (
    <AuthLayout
      title="Welcome to Ellie"
      quote={`"We are what we repeatedly do. Excellence, therefore, is not an act, but a habit."`}
      quoteAuthor="Aristotle">
      <Grid
        container
        sx={{
          display: 'flex',
          justifyContent: 'center',
          mt: 2,
          gap: 2,
        }}>
        <Button
          onClick={handleSignup}
          variant="text"
          color="primary"
          sx={{ borderRadius: 1 }}>
          <Typography fontFamily="Inter" fontWeight="bold" fontSize={14}>
            Sign Up
          </Typography>
        </Button>

        <Button
          variant="contained"
          color="primary"
          onClick={handleLogin}
          sx={{ borderRadius: 1 }}>
          <Typography fontFamily="Inter" fontWeight="bold" fontSize={14}>
            Login
          </Typography>
        </Button>
      </Grid>
    </AuthLayout>
  )
}

export default Landing
