import React from 'react'
import { Box, Grid, IconButton, Typography } from '@mui/material'
import { Clear } from '@mui/icons-material'

const MaterialItem = (props) => {
  return (
    <Grid item xs={12} sx={{ my: 1 }}>
      <Box
        onClick={props.onClick}
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          gap: 2,
          padding: 2,
          backgroundColor: 'card.main',
          borderRadius: 2,
          cursor: 'pointer',
          overflow: 'hidden',
          ':hover': {
            backgroundColor: 'card.mainHover',
            boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.2)',
          },
        }}>
        <Box>
          <Typography fontFamily="Inter" fontWeight="bold" fontSize={14}>
            {props.material.name}
          </Typography>
          <Typography fontFamily="Inter" fontSize={14}>
            {props.material.link}
          </Typography>
        </Box>
        {!props.courseId && (
          <IconButton
            onClick={(e) => {
              e.stopPropagation()
              props.onRemove()
            }}
            size="small">
            <Clear />
          </IconButton>
        )}
      </Box>
    </Grid>
  )
}

export default MaterialItem
