import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom/cjs/react-router-dom'
import { connect } from 'react-redux'
import { Box, Button, CircularProgress, Typography } from '@mui/material'

import api from 'lib/api'
import { setSnackbar } from 'reducers/ui'
import { setCurrentSchoolCode } from 'reducers/user'
import moment from 'moment'
import { useAuth0 } from '@auth0/auth0-react'

const ClassCode = (props) => {
  const { firstName, lastName, currentSchoolId, currentClassroomId } = props
  const location = useLocation()

  const [loading, setLoading] = useState(false)
  const [classCode, setClassCode] = useState('')
  const { getAccessTokenSilently } = useAuth0()

  useEffect(() => {
    const fetchClassCode = async () => {

      const token = await getAccessTokenSilently()

      if (!currentSchoolId || !currentClassroomId) {
        console.error('currentSchoolId or currentClassroomId is not defined');
        return;
      }

      setLoading(true)
      try {
        const response = await api.classroom.getCode({
          schoolId: currentSchoolId,
          classroomId: currentClassroomId,
        }, token)
        if (response.data) {
          setClassCode(response.data)
          props.setCurrentSchoolCode(response.data)
        } else {
          props.setSnackbar({
            open: true,
            message: 'Failed to retrieve class code',
            severity: 'error',
          })
        }
      } catch (e) {
        props.setSnackbar({ 
          open: true,
          message: e.message,
          severity: 'error',
        })
      } finally {
        setLoading(false)
      }
    }

    if (currentSchoolId && currentClassroomId) {
      fetchClassCode()
    }
  }, [currentSchoolId, currentClassroomId, props.setCurrentSchoolCode, props.setSnackbar])

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, my: 1 }}>
      <Typography sx={{
        fontFamily: 'Inter',
        fontSize: '16px',
        fontWeight: 600,
        lineHeight: '19.36px',
        letterSpacing: '0.02em',
        textAlign: 'left',
      }}>
        Invite Students
      </Typography>
      {loading ? (
        <Box sx={{ display: 'flex', justifyContent: 'center', backgroundColor: 'white', p: 2, borderRadius: 2 }}>
          <CircularProgress />
        </Box>
      ) : (
        <Box sx={{
          display: 'flex',
          backgroundColor: 'white',
          p: 2,
          borderRadius: 2,
          justifyContent: 'space-between',
          alignItems: 'center',
        }}>
          <Typography fontFamily="Inter" color="primary" fontSize={16} fontWeight={600}>
            Sign-up Code:
          </Typography>
          <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
            <Typography fontFamily="Inter" color="primary" fontSize={16} fontWeight={600}>
              {classCode}
            </Typography>
            <Button
              onClick={() => {
                navigator.clipboard.writeText(classCode)
                props.setSnackbar({
                  open: true,
                  message: 'Join code copied to clipboard',
                  severity: 'success',
                })
              }}
              variant="outlined"
              sx={{ p: 1, width: 'fit-content', borderRadius: 1, height: 30 }}>
              <Typography fontFamily="Inter" fontSize={14}>
                Copy
              </Typography>
            </Button>
          </Box>
        </Box>
      )}
    </Box>
  )
}

const mapStateToProps = (state) => ({
  firstName: state.user.firstName,
  lastName: state.user.lastName,
  currentSchoolId: state.user.currentSchoolId,
  currentClassroomId: state.user.currentClassroomId,
})

const mapDispatchToProps = {
  setCurrentSchoolCode,
  setSnackbar,
}

export default connect(mapStateToProps, mapDispatchToProps)(ClassCode)