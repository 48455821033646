import React from 'react'
import ReactDOM from 'react-dom/client'
import { Provider } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react'
import * as Sentry from '@sentry/react'
import pkg from '../package.json'

import '@fontsource/roboto/300.css'
import '@fontsource/roboto/400.css'
import '@fontsource/roboto/500.css'
import '@fontsource/roboto/700.css'
import 'index.css'

import App from './App'
import { store, persistor } from 'store'
import Loading from 'components/Loading'
import posthog from 'posthog-js'
import { Auth0Provider } from '@auth0/auth0-react';

posthog.init('phc_exy9efp21zUlrfBeRJuZE1q5F5Ucc2NTpgdlMdA5Ea1',
    {
        api_host: 'https://us.i.posthog.com',
        person_profiles: 'identified_only' // or 'always' to create profiles for anonymous users as well
    }
)

// auth0 configuration
const auth0Config = {
  domain: process.env.REACT_APP_AUTH0_DOMAIN,
  clientId: process.env.REACT_APP_AUTH0_CLIENT_ID,
  cacheLocation: "localstorage",
  authorizationParams: {
    redirect_uri: `${window.location.origin}/auth0-callback`,
    audience: "http://myta",
    scope: "openid profile email",
    appState: {
      returnTo: window.location.pathname
    }
  },
  useRefreshTokens: false
};

Sentry.init({
  dsn: '',
  release: `${pkg.name}@${pkg.version}`,
  environment: process.env.NODE_ENV,
  integrations: [new Sentry.BrowserTracing(), new Sentry.Replay()],
  tracesSampleRate: 1.0, // Capture 100% of the transactions, reduce in production!
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  normalizeDepth: 10,
  enabled: process.env.NODE_ENV !== 'development',
})

const root = ReactDOM.createRoot(document.getElementById('root'))

root.render(
  <Provider store={store}>
    <PersistGate loading={<Loading loading backdrop />} persistor={persistor}>
      <Auth0Provider {...auth0Config}>
        <App />
      </Auth0Provider>
    </PersistGate>
  </Provider>
)
