export const validateEmail = (email) => {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
  const isEmailValid = emailRegex.test(email)

  if (!isEmailValid) return 'Invalid email address'

  return true
}

export const isStrongPassword = (password) => {
  const uppercaseRegex = /[A-Z]/
  const lowercaseRegex = /[a-z]/
  const specialCharRegex = /[!@#$%^&*(),.?":{}|<>]/
  const numberRegex = /[0-9]/
  const lengthRegex = /.{8,}/

  let suggestions = []

  if (!uppercaseRegex.test(password)) {
    suggestions.push('Include at least one uppercase letter')
  }

  if (!lowercaseRegex.test(password)) {
    suggestions.push('Include at least one lowercase letter')
  }

  if (!specialCharRegex.test(password)) {
    suggestions.push('Include at least one special character')
  }

  if (!numberRegex.test(password)) {
    suggestions.push('Include at least one number')
  }

  if (!lengthRegex.test(password)) {
    suggestions.push('Password should be at least 8 characters long')
  }

  return suggestions.length > 0 ? suggestions.join('\n') : true
}
