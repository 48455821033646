import React, { useState } from 'react'
import { connect } from 'react-redux'
import {
  TextField,
  FormControlLabel,
  Checkbox,
  Link,
  Grid,
} from '@mui/material'
import { LoadingButton } from '@mui/lab'

// import api from 'lib/api'
import AuthLayout from 'layouts/Auth'
import { setUser } from 'reducers/user'
import { setSnackbar } from 'reducers/ui'
import { validateEmail } from 'lib/helpers'

const SignupPage = (props) => {
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')
  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [username, setUsername] = useState('')
  const [loading, setLoading] = useState(false)

  const handleSignup = async () => {
    setLoading(true)
    try {
      props.setSnackbar({
        message:
          'Lyceum is not currently accepting new user registrations.  Please try again later.',
        severity: 'info',
      })
      // const response = await api.auth.register({
      //   email,
      //   password,
      //   username,
      // })

      // props.setUser(response.data.user)
    } catch (e) {
      props.setSnackbar({ open: true, message: e.message, severity: 'error' })
    } finally {
      setLoading(false)
    }
  }

  return (
    <AuthLayout title="Sign up">
      <Grid container mt={2}>
        <Grid item xs={12} sm={6} pr={1}>
          <TextField
            autoComplete="given-name"
            name="firstName"
            required
            fullWidth
            id="firstName"
            label="First Name"
            size="small"
            autoFocus
            sx={{ mb: 3 }}
            InputProps={{
              sx: {
                backgroundColor: 'background.paper',
              },
            }}
            value={firstName}
            onChange={(e) => setFirstName(e.target.value)}
          />
        </Grid>
        <Grid item xs={12} sm={6} pl={1}>
          <TextField
            required
            fullWidth
            id="lastName"
            label="Last Name"
            name="lastName"
            autoComplete="family-name"
            size="small"
            sx={{ mb: 3 }}
            InputProps={{
              sx: {
                backgroundColor: 'background.paper',
              },
            }}
            value={lastName}
            onChange={(e) => setLastName(e.target.value)}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            required
            fullWidth
            id="username"
            label="Username"
            name="username"
            size="small"
            sx={{ mb: 3 }}
            InputProps={{
              sx: {
                backgroundColor: 'background.paper',
              },
            }}
            value={username}
            onChange={(e) => setUsername(e.target.value)}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            required
            fullWidth
            id="email"
            label="Email Address"
            name="email"
            autoComplete="email"
            size="small"
            sx={{ mb: 3 }}
            InputProps={{
              sx: {
                backgroundColor: 'background.paper',
              },
            }}
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            required
            fullWidth
            name="password"
            label="Password"
            type="password"
            id="password"
            autoComplete="new-password"
            size="small"
            sx={{ mb: 3 }}
            InputProps={{
              sx: {
                backgroundColor: 'background.paper',
              },
            }}
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            required
            fullWidth
            name="confirmPassword"
            label="Confirm Password"
            type="password"
            id="confirmPassword"
            size="small"
            sx={{ mb: 3 }}
            error={confirmPassword !== password}
            InputProps={{
              sx: {
                backgroundColor: 'background.paper',
              },
            }}
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
          />
        </Grid>
        <Grid item xs={12}>
          <FormControlLabel
            control={<Checkbox value="allowExtraEmails" color="primary" />}
            label="I want to receive inspiration, marketing promotions and updates via email."
          />
        </Grid>
      </Grid>
      <LoadingButton
        disabled={
          loading ||
          password !== confirmPassword ||
          // firstName === '' ||
          // lastName === '' ||
          username === '' ||
          email === '' ||
          Boolean(validateEmail(email) !== true) ||
          password === '' ||
          confirmPassword === ''
        }
        loading={loading}
        sx={{
          color: 'primary.contrastText',
          fontFamily: 'Inter',
          fontWeight: 'bold',
          fontSize: 14,
          my: 1,
          borderRadius: 1,
        }}
        color="primary"
        variant="contained"
        onClick={handleSignup}>
        Sign Up
      </LoadingButton>
      <Grid container justifyContent="flex-end" mt={1}>
        <Grid item>
          <Link
            fontFamily="Inter"
            href="/login"
            variant="body2"
            color="secondary.dark">
            Already have an account? Sign in
          </Link>
        </Grid>
      </Grid>
    </AuthLayout>
  )
}

const mapDispatchToProps = {
  setUser,
  setSnackbar,
}

export default connect(null, mapDispatchToProps)(SignupPage)
