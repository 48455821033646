import { Box } from '@mui/material'

const AppLine = ({ color, sx }) => {
  return (
    <Box
      sx={{
        width: '100%',
        height: 1.2,
        backgroundColor: `${color ? color : 'background.sideNav'}`,
        ...sx,
      }}
    />
  )
}

export default AppLine
