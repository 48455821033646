import React from 'react'
import { Box, Tooltip as MuiTooltip } from '@mui/material'
import { tooltipClasses } from '@mui/material/Tooltip'
import styled from '@emotion/styled'
import { Check } from '@mui/icons-material'

const Tooltip = styled(({ className, ...props }) => (
  <MuiTooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#EDEDED',
    padding: 12,
    color: 'black',
    boxShadow: theme.shadows[4],
    fontSize: 16,
    fontFamily: 'Inter',
  },
}))

const ObjectiveProgressIndicator = ({ analysis }) => (
  <Tooltip
    title={analysis && analysis.details ? analysis.details : 'Not yet started.'}
    sx={{
      tooltip: {
        backgroundColor: 'white',
        color: 'black',
        boxShadow: 1,
        fontSize: '14px',
      },
    }}>
    <Box
      sx={{
        width: analysis?.completedAt ? 30 : 20,
        pl: analysis?.completedAt ? 0 : 1,
        ml: analysis?.completedAt ? -1 : 0,
        display: 'flex',
      }}>
      {analysis?.completedAt ? (
        <Check fontSize="large" color="primary" />
      ) : (
        <Box
          width=".2rem"
          height="1.5rem"
          display="flex"
          alignItems="center"
          justifyContent="center"
          sx={{
            transform: 'rotate(45deg)',
            color: 'black',
            backgroundColor: analysis ? '#DC0029' : '#9FB8D7',
          }}
        />
      )}
    </Box>
  </Tooltip>
)

export default ObjectiveProgressIndicator
