import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { useLocation, useHistory } from 'react-router-dom/cjs/react-router-dom'
import {
  Box,
  Button,
  IconButton,
  LinearProgress,
  Typography,
} from '@mui/material'
import { ChevronLeft } from '@mui/icons-material'

import { setSelectedActivity } from 'reducers/user'
import ObjectiveWithProgress from 'components/teacher/ObjectiveWithProgress'
import { ReactComponent as TrippleDotIcon } from '../../assets/tripple-dot-icon.svg'
// import SentimentPieChart from './SentimentPieChart'

const ActivityItem = (props) => {
  const {
    refreshing,
    selectedActivity,
    item,
    open: forceOpen,
    objectivesProgress,
    objectivesProgressLoading,
    sentimentProgress,
    sentimentProgressLoading,
  } = props
  const location = useLocation()
  const history = useHistory()

  const [open, setOpen] = useState()

  useEffect(() => {
    setOpen(forceOpen)
  }, [forceOpen])

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'space-between',
        gap: 2,
        p: 2,
        backgroundColor: open ? 'primary.contrastText' : 'primary.weak',
        borderRadius: 2,
        width: '100%',
        cursor: !selectedActivity && 'pointer',
        ':hover': {
          backgroundColor: !selectedActivity && !open && 'primary.weakHover',
          boxShadow: !selectedActivity && '0px 0px 10px rgba(0, 0, 0, 0.2)',
        },
      }}
      onClick={() => {
        if (!selectedActivity) {
          props.setSelectedActivity(item)
          history.push(`${location.pathname}/activity/${item.activity.id}`)
        }
      }}>
      <Box
        sx={{
          display: 'flex',
          gap: 2,
          alignItems: 'center',
          justifyContent: 'space-between',
          width: '100%',
        }}>
        <Box sx={{ display: 'flex', gap: 2, alignItems: 'center' }}>
          <TrippleDotIcon />
          <Typography fontFamily="Inter" fontSize={14}>
            {item.activity.name}
          </Typography>
        </Box>
        {!forceOpen && (
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
            <Button
              onClick={(e) => {
                e.stopPropagation()
                props.setSelectedActivity(item)
                history.push(
                  `${location.pathname}/activity/${item.activity.id}/edit`
                )
              }}
              variant="outlined"
              sx={{
                borderRadius: 1,
                px: 1,
                minWidth: 50,
                ':hover': {
                  backgroundColor: 'primary.weak',
                },
              }}>
              <Typography fontFamily="Inter" fontSize={12} fontWeight="bold">
                Edit
              </Typography>
            </Button>
            <IconButton
              onClick={(e) => {
                setOpen((prev) => !prev)
                e.stopPropagation()
              }}>
              <ChevronLeft
                color="primary.main"
                sx={{ transform: open && 'rotate(-90deg)' }}
              />
            </IconButton>
          </Box>
        )}
      </Box>
      {open && (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            width: '100%',
          }}>
          {objectivesProgressLoading ? (
            <Box sx={{ mt: 2, mb: 4, px: 4 }}>
              <LinearProgress sx={{ height: 10 }} />
            </Box>
          ) : (
            item?.objectives?.map((objective, index) =>
              selectedActivity ? (
                <ObjectiveWithProgress
                  key={index}
                  description={objective.details}
                  loading={refreshing}
                  progress={
                    objectivesProgress
                      ? (objectivesProgress[objective.id]?.completed /
                          objectivesProgress[objective.id]?.total) *
                        100
                      : 0
                  }
                />
              ) : (
                <Typography fontFamily="Inter" fontSize={14} my={1}>
                  <strong>Objective:</strong> {objective.details}
                </Typography>
              )
            )
          )}
          {selectedActivity && (
            <Box
              sx={{
                display: 'flex',
                gap: 2,
                alignItems: 'center',
                pl: 4,
              }}>
              <Box
                sx={{ display: 'flex', gap: 2, alignItems: 'center', my: 2 }}>
                <Box
                  sx={{
                    width: 20,
                    height: 10,
                    backgroundColor: 'graph.completed',
                  }}
                />
                <Typography fontFamily="Inter" fontSize={14}>
                  Completed
                </Typography>
              </Box>
              <Box sx={{ display: 'flex', gap: 2, alignItems: 'center' }}>
                <Box
                  sx={{
                    width: 20,
                    height: 10,
                    backgroundColor: 'graph.incomplete',
                  }}
                />
                <Typography fontFamily="Inter" fontSize={14}>
                  Not Completed
                </Typography>
              </Box>
            </Box>
          )}
          {/* {selectedActivity && <SentimentPieChart />} */}
        </Box>
      )}
    </Box>
  )
}

const mapStateToProps = (state) => ({
  selectedActivity: state.user.selectedActivity,
})

const mapDispatchToProps = {
  setSelectedActivity,
}

export default connect(mapStateToProps, mapDispatchToProps)(ActivityItem)
