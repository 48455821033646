import React from 'react'
import { Box, Typography } from '@mui/material'

const CustomPageIndicator = ({ number, isActive, onClick }) => {
  return (
    <Box
      onClick={onClick}
      sx={{
        color: 'primary.contrastText',
        cursor: 'pointer',
        fontWeight: 'bold',
        textDecoration: 'none',
        width: 40,
        height: 40,
        backgroundColor: isActive ? 'primary.light' : 'secondary.light',
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: 2,
      }}>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          width: '100%',
          height: '100%',
        }}>
        <Typography
          fontFamily="Inter"
          fontSize={16}
          color="primary.contrastText"
          fontWeight="bold">
          {number}
        </Typography>
      </Box>
    </Box>
  )
}

export default CustomPageIndicator
