import React, { useState } from 'react'
import { connect } from 'react-redux'
import { Box, Toolbar } from '@mui/material'

import Navbar from 'components/admin/Navbar'
import Sidenav from 'components/admin/Sidenav'
import { useWindowWidth } from 'hooks/useWindowWidth'

const Navigation = (props) => {
  const { role, master } = props
  const [mobileDrawerOpen, setMobileDrawerOpen] = useState(false)

  const width = useWindowWidth()

  let routes = [
    {
      name: 'Dashboard',
      path: '/',
    },
    {
      name: 'Schools',
      path: '/schools',
    },
    {
      name: 'Classrooms',
      path: '/classrooms',
    },
    {
      name: 'Courses',
      path: '/courses',
    },
    {
      name: 'Teachers',
      path: '/teachers',
    },
    {
      name: 'Students',
      path: '/students',
    },
  ]

  if (role === 'super_admin') {
    let userRoutes = [routes[4], routes[5]]
    routes = [routes[0]]
    if (master) {
      routes.push({
        name: 'Admins',
        path: '/admins',
      })
    }
    routes.push(
      {
        name: 'School Admins',
        path: '/school-admins',
      },
      // {
      //   name: 'Plans',
      //   path: '/plans',
      // },
      {
        name: 'Roles',
        path: '/roles',
      },
      ...userRoutes
    )
  }

  return (
    <Box style={{ display: 'flex', flexDirection: 'column', height: '100vh' }}>
      <Navbar routes={routes} setMobileDrawerOpen={setMobileDrawerOpen} />
      <Toolbar />
      <Box
        sx={{
          display: 'flex',
          backgroundColor: 'background.sideNav',
          flexGrow: 1,
        }}>
        <Sidenav
          routes={routes}
          mobileDrawerOpen={mobileDrawerOpen}
          setMobileDrawerOpen={setMobileDrawerOpen}
        />
        <Box
          sx={{
            backgroundColor: 'background.default',
            width:
              width >= 1200 && width <= 1410 ? 'calc(100% - 266px)' : '100%',
            p: 2,
          }}>
          {props.children}
        </Box>
      </Box>
    </Box>
  )
}

const mapStateToProps = (state) => ({
  role: state.user.role,
  master: state.user.master,
})

export default connect(mapStateToProps)(Navigation)
