import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom/cjs/react-router-dom'
import { connect } from 'react-redux'
import { TextField, Box } from '@mui/material'
import { LoadingButton } from '@mui/lab'

import api from 'lib/api'
import AuthLayout from 'layouts/Auth'
import { setUser } from 'reducers/user'
import { setSnackbar } from 'reducers/ui'

const StudentCreateAccount = (props) => {
  const { search } = useLocation()

  const [classroomCode, setClassroomCode] = useState('')  // Changed from code to classroomCode
  const [teacherFirstName, setTeacherFirstName] = useState('')
  const [teacherLastName, setTeacherLastName] = useState('')
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    const params = new URLSearchParams(search)
    const c = params.get('c')
    const tF = params.get('tF')
    const tL = params.get('tL')

    setClassroomCode(c || '')  // Changed from setCode to setClassroomCode
    setTeacherFirstName(tF)
    setTeacherLastName(tL)

    //eslint-disable-next-line
  }, [])

  const handleCreateAccount = async () => {
    setLoading(true)
    try {
      const response = await api.user.auth.register({
        email,
        password,
        firstName,
        lastName,
        rolesId: 4,
        joinSchool: { classroom_code: classroomCode },
      })

      props.setUser({
        ...response.data.user,
        role: response.data.role.name,
      })
    } catch (e) {
      props.setSnackbar({ open: true, message: e.message, severity: 'error' })
    } finally {
      setLoading(false)
    }
  }

  return (
    <AuthLayout
      title={`${teacherFirstName ? teacherFirstName + ' ' : ''}${
        teacherLastName
          ? teacherLastName + ' has invited you'
          : 'You have been invited'
      } to Ellie.`}
      quote={`Create your \nEllie Account`}
      quoteAuthor="">
      <Box>
        <TextField
          variant="outlined"
          margin="normal"
          fullWidth
          id="classroomCode"
          label="Sign-up Code"
          name="classroomCode"
          autoComplete="one-time-code"
          autoFocus
          value={classroomCode}
          onChange={(e) => setClassroomCode(e.target.value)}
          InputProps={{
            sx: {
              backgroundColor: 'background.paper',
            },
          }}
        />

        <TextField
          variant="outlined"
          margin="normal"
          fullWidth
          id="email"
          label="Your Email"
          name="email"
          autoComplete="name"
          autoFocus
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          InputProps={{
            sx: {
              backgroundColor: 'background.paper',
            },
          }}
        />
        <TextField
          variant="outlined"
          margin="normal"
          fullWidth
          name="password"
          label="Create Password"
          type="password"
          id="password"
          autoComplete="current-password"
          InputProps={{
            sx: {
              backgroundColor: 'background.paper',
            },
          }}
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          onKeyDown={(e) => {
            if (e.key === 'Enter') handleCreateAccount()
          }}
        />
        <TextField
          variant="outlined"
          margin="normal"
          fullWidth
          name="firstName"
          label="First Name"
          id="firstName"
          autoComplete="first-name"
          InputProps={{
            sx: {
              backgroundColor: 'background.paper',
            },
          }}
          value={firstName}
          onChange={(e) => setFirstName(e.target.value)}
        />
        <TextField
          variant="outlined"
          margin="normal"
          fullWidth
          name="lastName"
          label="Last Name"
          id="lastName"
          autoComplete="last-name"
          InputProps={{
            sx: {
              backgroundColor: 'background.paper',
            },
          }}
          value={lastName}
          onChange={(e) => setLastName(e.target.value)}
          onKeyDown={(e) => {
            if (e.key === 'Enter') handleCreateAccount()
          }}
        />
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            marginTop: 2,
          }}>
          <Box
            sx={{
              color: 'primary.contrastText',
              my: 1,
              alignSelf: 'center',
              width: 2,
            }}></Box>
          <LoadingButton
            loading={loading}
            sx={{
              color: 'primary.contrastText',
              my: 1,
              borderRadius: 1,
            }}
            color="primary"
            variant="contained"
            onClick={handleCreateAccount}>
            Create Account
          </LoadingButton>
        </Box>
      </Box>
    </AuthLayout>
  )
}

const mapDispatchToProps = {
  setUser,
  setSnackbar,
}

export default connect(null, mapDispatchToProps)(StudentCreateAccount)
