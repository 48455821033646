import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom/cjs/react-router-dom'
import { connect } from 'react-redux'

import { Box, Button, CircularProgress, Typography, Paper, Avatar } from '@mui/material'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { Add, Delete } from '@mui/icons-material'
import IconButton from '@mui/material/IconButton'

import api from 'lib/api'
import { setSnackbar } from 'reducers/ui'
import AppLine from 'components/teacher/AppLine'
import StudentItem from 'components/teacher/StudentItem'
import CustomPaginationBar from 'components/teacher/CustomPaginationBar'
import StudentNotesModal from 'components/modals/StudentNotesModal'
import CreateUserModal from 'components/modals/CreateUserModal'
import Modal from 'components/Modal'
import { useAuth0 } from '@auth0/auth0-react'

const StudentTable = (props) => {
  const { getAccessTokenSilently } = useAuth0()
  const {
    noRefresh,
    refreshing,
    currentSchoolId,
    currentSchoolCode,
    currentClassroomId,
    currentClassroomTeacherId,
    selectedActivity,
  } = props

  const { courseId, activityId } = useParams()

  const [page, setPage] = useState(1)
  const [loading, setLoading] = useState([])
  const [viewAll, setViewAll] = useState(false)
  const [students, setStudents] = useState([])
  const [selectedStudent, setSelectedStudent] = useState()

  const [studentNotesModalOpen, setStudentNotesModalOpen] = useState(false)
  const [createStudentModalOpen, setCreateStudentModalOpen] = useState(false)

  const [tick, setTick] = useState(0)
  const [refresh, setRefresh] = useState(false)

  const [deleteConfirmOpen, setDeleteConfirmOpen] = useState(false)
  const [studentToDelete, setStudentToDelete] = useState(null)

  let objectivesProgress = []
  let sentimentsProgress = []

  const toggleStudentNotesModalOpen = () =>
    setStudentNotesModalOpen((prev) => !prev)

  const toggleCreateStudentModalOpen = () =>
    setCreateStudentModalOpen((prev) => !prev)

  const paginateArray = (longArray, pageSize = 10) => {
    const paginatedArray = []
    for (let i = 0; i < longArray.length; i += pageSize) {
      paginatedArray.push(longArray.slice(i, i + pageSize))
    }
    return paginatedArray
  }

  const handleDeleteClick = (student) => {
    setStudentToDelete(student)
    setDeleteConfirmOpen(true)
  }

  const handleConfirmDelete = () => {
    if (studentToDelete) {
      removeStudent(studentToDelete.id)
    }
    setDeleteConfirmOpen(false)
  }

  const handleCancelDelete = () => {
    setDeleteConfirmOpen(false)
    setStudentToDelete(null)
  }

  const removeStudent = async (studentId) => {
    try {
      setLoading(true)
      const token = await getAccessTokenSilently()
      await api.classroom.updatePupils({
        schoolId: currentSchoolId,
        classroomId: currentClassroomId,
        removePupils: [studentId],
      }, token)

      const updatedStudents = students
        .map((page) => page.filter((student) => student.id !== studentId))
        .filter((page) => page.length > 0)

      setStudents(updatedStudents)
      props.setSnackbar({
        open: true,
        message: 'Student removed successfully',
        severity: 'success',
      })
    } catch (e) {
      props.setSnackbar({
        open: true,
        message: e.message,
        severity: 'error',
      })
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    const interval = setInterval(() => {
      setTick((prev) => {
        setRefresh(true)
        if (props.setRefreshing) props.setRefreshing(true)
        return prev + 1
      })
    }, 10000)

    return () => clearInterval(interval)
    // eslint-disable-next-line
  }, [tick])

  useEffect(() => {
    const fetchAnalytics = async (student, objectiveId) => {
      try {
        const token = await getAccessTokenSilently()
        const response = await api.course.getAnalytics({
          schoolId: currentSchoolId,
          classroomTeacherId: currentClassroomTeacherId,
          courseId,
          activityId,
          objectiveId,
          pupilId: student.id,
        }, token)

        if (response.data.analytics) {
          // eslint-disable-next-line
          objectivesProgress = [
            ...objectivesProgress,
            response?.data?.analytics[0] // ? means if it's there - unknown value, could be null
              ? response.data.analytics[0]
              : {
                  coursesActivitiesObjectivesId: objectiveId,
                  completedAt: null,
                },
          ]
        }

        return response.data.analytics[0]
      } catch (e) {
        props.setSnackbar({
          open: true,
          message: e.message,
          severity: 'error',
        })
      }
    }

    const fetchSentiments = async (student, objectiveId) => {
      try {
        const token = await getAccessTokenSilently()
        const response = await api.course.getSentiments({
          schoolId: currentSchoolId,
          classroomTeacherId: currentClassroomTeacherId,
          courseId,
          activityId,
          pupilId: student.id,
        }, token)

        /*if (response.data.analytics) {
          // eslint-disable-next-line
          sentimentScores = [
            ...sentimentScores,
            response?.data?.analytics[0] // ? means if it's there - unknown value, could be null
              ? response.data.analytics[0]
              : {
                  focused: 0,
                  excitement: 0,
                  curiosity: 0,
                  confused: 0,
                  frustrated: 0,
                  boredom: 0,
                },
          ]
        }*/

        return response.data.analytics[0]
      } catch (e) {
        props.setSnackbar({
          open: true,
          message: e.message,
          severity: 'error',
        })
      }
    }

    const fetchNotes = async (student) => {
      try {
        const token = await getAccessTokenSilently()
        const response = await api.note.get({
          schoolId: currentSchoolId,
          classroomTeacherId: currentClassroomTeacherId,
          courseId,
          studentId: student.id,
        }, token)

        return response.data.note.note
      } catch (e) {
        return ''
      }
    }

    const fetchStudents = async () => {
      if (!refreshing && !noRefresh) {
        setLoading(true)
        if (props.setObjectivesProgressLoading)
          props.setObjectivesProgressLoading(true)
        if (props.setSentimentsProgressLoading)
          props.setSentimentsProgressLoading(true)
      }

      try {
        const token = await getAccessTokenSilently()
        const response = await api.classroom.list({
          schoolId: currentSchoolId,
          rowsPerPage: 9999,
          pupilsPerPage: 99999,
          teachersPerPage: 99999,
          mine: true,
        }, token)

        let students = await Promise.all(
          response.data.classrooms
            .find((c) => c.classroom.id === currentClassroomId)
            ?.pupils.map(async (student) => {
              const notes = await fetchNotes(student)

              let analytics
              if (activityId)
                analytics = await Promise.all(
                  selectedActivity?.objectives?.map(
                    async (objective) =>
                      await fetchAnalytics(student, objective.id)
                  )
                )

              let sentiment_analytics
              if (activityId)
                sentiment_analytics = await Promise.all(
                  selectedActivity?.objectives?.map(
                    async () =>
                      await fetchSentiments(student)
                  )
                )
                else
                  sentiment_analytics = {}

              if (props.setObjectivesProgress)
                props.setObjectivesProgress(
                  objectivesProgress.reduce((acc, curr) => {
                    const { coursesActivitiesObjectivesId, completedAt } = curr
                    if (!acc[coursesActivitiesObjectivesId]) {
                      acc[coursesActivitiesObjectivesId] = {
                        total: 0,
                        completed: 0,
                      }
                    }

                    acc[coursesActivitiesObjectivesId].total += 1

                    if (completedAt !== null) {
                      acc[coursesActivitiesObjectivesId].completed += 1
                    }

                    return acc
                  }, {})
                )

                /*if (props.setSentimentsProgress) ------------- so broken lol what was i trying to do
                  props.setSentimentsProgress(
                    sentimentsProgress.reduce((acc, curr) => {
                      let maximum = 0
                      dominantSentiment = ''
                      if (curr.focused > maximum)
                        maximum = curr.focused
                        dominantSentiment = 'focused'
                      if (curr.excitement > maximum)
                        maximum = curr.excitement
                        dominantSentiment = 'excited'
                      if (curr.curiosity > maximum)
                        maximum = curr.curiosity
                        dominantSentiment = 'curiosity'
                      if (curr.confused > maximum)
                        maximum = curr.confused
                        dominantSentiment = 'confused'
                      if (curr.frustrated > maximum)
                        maximum = curr.frustrated
                        dominantSentiment = 'frustrated'
                      if (curr.boredom > maximum)
                        maximum = curr.boredom
                        dominantSentiment = 'bored'
                      
                      return dominantSentiment
                    }, '')
                  )*/
              
              //TODO - MOCK OUT THE ACTUAL ASSIGNMENT

              return {
                id: student.id,
                name: student.firstName + ' ' + student.lastName,
                firstName: student.firstName,
                lastName: student.lastName,
                email: student.email,
                profileImageLink: student.profileImageLink,
                notes,
                analytics,
                sentiment_analytics,
              }
            })
        )

        if (viewAll) setStudents([students])
        else setStudents(paginateArray(students))
      } catch (e) {
        props.setSnackbar({ open: true, message: e.message, severity: 'error' })
      } finally {
        setLoading(false)
        if (props.setObjectivesProgressLoading)
          props.setObjectivesProgressLoading(false)
        if (props.setSentimentsProgressLoading)
          props.setSentimentsProgressLoading(false)

        if (props.setRefreshing) props.setRefreshing(false)
      }
    }

    if (!studentNotesModalOpen && !createStudentModalOpen && !refresh)
      fetchStudents()

    setRefresh(false)

    // eslint-disable-next-line
  }, [studentNotesModalOpen, createStudentModalOpen, refresh])

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: 'inputs.light',
        p: 4,
        borderRadius: 2,
        height: '89vh',
        position: 'relative',
      }}>
      <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, mb: 2 }}>
        {refreshing && (
          <Box
            sx={{
              display: 'flex',
              position: 'absolute',
              top: 30,
              right: 30,
              gap: 2,
            }}>
            <Typography fontFamily="Inter" fontSize={14} color="error">
              Refreshing
            </Typography>
            <CircularProgress size={20} color="error" />
          </Box>
        )}
        <Typography fontFamily="Inter" fontSize={24}>
          Students
        </Typography>
        <Button
          onClick={toggleCreateStudentModalOpen}
          startIcon={<Add />}
          variant="contained"
          sx={{
            borderRadius: 1,
            width: 'fit-content',
          }}>
          <Typography fontFamily="Inter" fontSize={14} fontWeight="bold">
            Add Student
          </Typography>
        </Button>
      </Box>
      <Box sx={{ display: 'flex', mb: 2, gap: 1 }}>
        <Box sx={{ display: 'flex', flex: 2, gap: 1 }}>
          <Typography fontFamily="Inter" fontSize={16}>
            Name
          </Typography>
          <ExpandMoreIcon />
        </Box>
        {selectedActivity && (
          <>
          <Typography fontFamily="Inter" fontSize={16} sx={{ flex: 1 }}>
            Sentiment
          </Typography>
          <Typography fontFamily="Inter" fontSize={16} sx={{ flex: 1 }}>
            Objective Progress
          </Typography>
        </>
        )}
       {/*<Typography fontFamily="Inter" fontSize={16} sx={{ flex: 1 }}>
          Notes
        </Typography>*/}
        <Box sx={{ width: 48 }} /> {/* Spacer for delete button */}
      </Box>
      <AppLine color="inputs.main" />
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          overflow: 'hidden',
          justifyContent: 'space-between',
          height: '100%',
        }}>
        <Box
          sx={{
            overflowY: 'scroll',
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            gap: 1,
          }}>
          {loading ? (
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: '100%',
              }}>
              <CircularProgress size={120} />
            </Box>
          ) : (
            students &&
            students.length > 0 &&
            students[page - 1].map((student, index) => (
              <Box
                key={index}
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: 1,
                  mt: index === 0 && 1,
                }}>
                <Box
                  sx={{ display: 'flex', alignItems: 'center', width: '100%' }}>
                  <StudentItem
                    student={student}
                    setSelectedStudent={setSelectedStudent}
                    toggleStudentNotesModalOpen={toggleStudentNotesModalOpen}
                    showNotes={true}
                  />
                  <IconButton
                    onClick={() => handleDeleteClick(student)}
                    color="error"
                    sx={{ ml: 2 }}>
                    <Delete />
                  </IconButton>
                </Box>
                <AppLine color="lightgray" />
              </Box>
            ))
          )}
        </Box>
        <CustomPaginationBar
          page={page}
          setPage={setPage}
          length={students?.length}
          viewAll={viewAll}
          toggleViewAll={() => {
            setPage(1)
            if (!viewAll) setStudents([students.flat(1)])
            else setStudents(paginateArray(students.flat(1)))
            setViewAll((prev) => !prev)
          }}
        />
      </Box>

      <StudentNotesModal
        open={studentNotesModalOpen}
        handleClose={toggleStudentNotesModalOpen}
        student={selectedStudent}
      />

      <CreateUserModal
        open={createStudentModalOpen}
        onClose={toggleCreateStudentModalOpen}
        rolesId={4}
        code={currentSchoolCode}
        students={students.flat(1)}
        addToClassroom
      />

      <Modal
        open={deleteConfirmOpen}
        onClose={handleCancelDelete}
        onSubmit={handleConfirmDelete}
        submitText="Remove"
        cancelText="Cancel"
        maxWidth="md"
        fullWidth>
        <Paper
          elevation={0}
          sx={{ p: 3, backgroundColor: 'background.default' }}>
          <Typography variant="body1" paragraph>
            Are you sure you want to remove this student?
          </Typography>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              my: 2,
              p: 2,
              bgcolor: 'background.paper',
            }}>
            <Avatar
              src={studentToDelete?.profileImageLink}
              sx={{ width: 48, height: 48, mr: 2 }}>
              {studentToDelete?.name.charAt(0)}
            </Avatar>
            <Typography variant="h6">{studentToDelete?.name}</Typography>
          </Box>
          {/* <Typography variant="body2" color="error" sx={{ mt: 2 }}>
            This action cannot be undone.
          </Typography> */}
        </Paper>
      </Modal>
    </Box>
  )
}

const mapStateToProps = (state) => ({
  currentSchoolId: state.user.currentSchoolId,
  currentSchoolCode: state.user.currentSchoolCode,
  currentClassroomId: state.user.currentClassroomId,
  currentClassroomTeacherId: state.user.currentClassroomTeacherId,
  selectedActivity: state.user.selectedActivity,
})

const mapDispatchToProps = {
  setSnackbar,
}

export default connect(mapStateToProps, mapDispatchToProps)(StudentTable)
