import { createSlice } from '@reduxjs/toolkit'

const userSlice = createSlice({
  name: 'user',
  initialState: {
    id: null,
    email: null,
    profileImageLink: null,
    username: null,
    firstName: null,
    lastName: null,
    role: null,
    token: null,
    isActive: null,
    master: null,
    selectedCourse: null,
    selectedActivity: null,
    currentSchoolId: null,
    currentSchoolCode: null,
    currentClassroomTeacherId: null,
    currentClassroomId: null,
    flaggedCourses: [],
  },
  reducers: {
    setToken(state, action) {
      state.token = action.payload
    },
    setAdmin(state, action) {
      const { id, email, firstName, lastName, role, master, token } =
        action.payload
      state.id = id
      state.email = email
      state.firstName = firstName
      state.lastName = lastName
      state.role = role
      state.master = master
      state.token = token
    },
    setUser(state, action) {
      const {
        id,
        email,
        profileImageLink,
        username,
        firstName,
        lastName,
        role,
        token,
        isActive,
      } = action.payload
      state.id = id
      state.email = email
      state.profileImageLink = profileImageLink
      state.username = username
      state.firstName = firstName
      state.lastName = lastName
      state.role = role
      state.token = token
      state.isActive = isActive
    },
    clearUser(state) {
      state.id = null
      state.email = null
      state.profileImageLink = null
      state.username = null
      state.firstName = null
      state.lastName = null
      state.role = null
      state.token = null
      state.isActive = null
      state.master = null
      state.selectedCourse = null
      state.selectedActivity = null
      state.currentSchoolId = null
      state.currentSchoolCode = null
      state.currentClassroomTeacherId = null
      state.currentClassroomId = null
      state.flaggedCourses = []
    },
    setSelectedClass(state, action) {
      state.selectedClass = action.payload
    },
    setSelectedCourse(state, action) {
      state.selectedCourse = action.payload
    },
    setSelectedActivity(state, action) {
      state.selectedActivity = action.payload
    },
    setCurrentSchoolId(state, action) {
      state.currentSchoolId = action.payload
    },
    setCurrentSchoolCode(state, action) {
      state.currentSchoolCode = action.payload
    },
    setCurrentClassroomTeacherId(state, action) {
      state.currentClassroomTeacherId = action.payload
    },
    setCurrentClassroomId(state, action) {
      state.currentClassroomId = action.payload
    },
    setFlaggedCourses(state, action) {
      state.flaggedCourses = action.payload
    },
  },
})

const { actions, reducer } = userSlice

export const {
  setToken,
  setAdmin,
  setUser,
  clearUser,
  setSelectedCourse,
  setSelectedActivity,
  setCurrentSchoolId,
  setCurrentSchoolCode,
  setCurrentClassroomTeacherId,
  setCurrentClassroomId,
  setFlaggedCourses,
} = actions

export default reducer
