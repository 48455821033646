import React from 'react'
import { connect } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { Box, Button, Grid, Typography } from '@mui/material'
import { Add } from '@mui/icons-material'

const GetStarted = (props) => {
  const { role, firstName } = props
  const history = useHistory()

  return (
    <Grid maxWidth container>
      <Grid item xs={12} sx={{ position: 'relative' }}>
        <Box
          sx={{
            width: '100%',
            height: '90vh',
            maxHeight: '90vh',
            overflowY: 'auto',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
          }}>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'start',
              alignItems: 'start',
              flexDirection: 'column',
              gap: 4,

              width: '40%',
              p: 2,
            }}>
            <Typography
              variant="h1"
              fontWeight="400"
              fontFamily="Besley"
              fontSize={36}
              textAlign="start">
              Welcome {firstName}
            </Typography>
            {role === 'teacher' ? (
              <Box>
                <Typography
                  fontFamily="Besley"
                  fontSize="18px"
                  fontWeight="400"
                  lineHeight="24.3px"
                  letterSpacing="0.01em"
                  textAlign="left">
                  Welcome to the Ellie Classroom Assistant that helps
                  personalize instruction according to your insights for each
                  student and offload tasks to allow more time for individual
                  student development. Let’s get started...
                </Typography>
                <Button
                  sx={{ mt: 2 }}
                  variant="contained"
                  startIcon={<Add />}
                  onClick={() => history.push('/class/create')}>
                  Create Class
                </Button>
              </Box>
            ) : (
              <Box>
                <Typography
                  fontFamily="Besley"
                  fontSize="18px"
                  fontWeight="400"
                  lineHeight="24.3px"
                  letterSpacing="0.01em"
                  textAlign="left">
                  Welcome to the Ellie Classroom Assistant!{' '}
                  <strong>Please contact your teacher to get started...</strong>
                </Typography>
              </Box>
            )}
          </Box>
        </Box>
        <Box
          sx={{
            px: 10,
            py: 10,
            maxWidth: 280,
            backgroundColor: 'card.main',
            justifyContent: 'center',
            alignItems: 'center',
            position: 'absolute',
            bottom: 10,
            right: 10,
          }}>
          <Typography fontFamily="Inter">
            Appears only until you're added to a class.
          </Typography>
        </Box>
      </Grid>
    </Grid>
  )
}

const mapStateToProps = (state) => ({
  userId: state.user.id,
  role: state.user.role,
  firstName: state.user.firstName,
})

export default connect(mapStateToProps)(GetStarted)
