import React from 'react'
import { useHistory } from 'react-router-dom'
import { Box, AppBar, Toolbar, Typography, IconButton } from '@mui/material'
import { Menu as MenuIcon } from '@mui/icons-material'

import Logo from 'assets/logo.png'

const Navbar = (props) => {
  const { setMobileDrawerOpen } = props
  const history = useHistory()

  return (
    <AppBar
      position="fixed"
      sx={{
        zIndex: (theme) => theme.zIndex.drawer + 1,
        backgroundColor: 'primary.dark',
      }}>
      <Toolbar sx={{ mx: { xs: 0, lg: 3 } }}>
        <Box sx={{ display: 'flex', flexGrow: 1, alignItems: 'center' }}>
          <IconButton
            color="inherit"
            onClick={() => setMobileDrawerOpen(true)}
            sx={{ mr: 1, display: { lg: 'none' } }}>
            <MenuIcon />
          </IconButton>
          <Box
            onClick={() => history.push('/')}
            sx={{
              display: 'flex',
              gap: 2,
              cursor: 'pointer',
            }}>
            <img src={Logo} alt="Lyceum" height={30} />
            <Typography
              variant="h6"
              noWrap
              component="div"
              sx={{
                mr: 2,
                textAlign: 'center',
                color: '#FFF',
              }}>
              Ellie
            </Typography>
          </Box>
        </Box>
      </Toolbar>
    </AppBar>
  )
}

export default Navbar
