import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'

import { Box, Button, Typography } from '@mui/material'
import { Close, Edit, Logout } from '@mui/icons-material'
import Avatar from './Avatar'
import EditUserModal from './modals/EditUserModal'
import { useAuth0 } from '@auth0/auth0-react'
import { handleUserLogout } from 'utils/logout'
import { clearUser } from 'reducers/user'

const MenuButton = (props) => {
  return (
    <Button
      variant="contained"
      {...props}
      sx={{
        borderRadius: 1,
        width: 'fit-content',
        ...props.sx,
      }}>
      <Typography fontFamily="Inter" fontWeight="bold" fontSize={14}>
        {props.children}
      </Typography>
    </Button>
  )
}

const PopupMenu = (props) => {
  const { user } = props
  const history = useHistory()
  const { logout } = useAuth0()

  const [showPopUp, setShowPopUp] = useState(false)
  const [editUserModalOpen, setEditUserModalOpen] = useState(false)

  const toggleEditUserModalOpen = () => setEditUserModalOpen((prev) => !prev)

  return (
    <Box sx={{ position: 'relative', zIndex: 99 }}>
      <Box
        onClick={() => setShowPopUp(false)}
        sx={{
          display: showPopUp ? 'flex' : 'none',
          position: 'absolute',
          zIndex: 99,
          width: '100vw',
          height: '100vh',
          top: 0,
          right: 0,
          cursor: 'pointer',
        }}
      />
      <Box
        sx={{
          width: 360,
          borderRadius: '6%',
          backgroundColor: 'rgba(255, 255, 255, 0.9)',
          display: showPopUp ? 'flex' : 'none',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'flex-end',
          paddingLeft: 5,
          paddingRight: 5,
          boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.05)',
          position: 'absolute',
          right: 20,
          top: 20,
          zIndex: 100,
          gap: 4,
          pt: 4,
          pb: 4,
        }}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            gap: 2,
          }}>
          <Box sx={{ position: 'relative', zIndex: 1 }}>
            <Box
              onClick={toggleEditUserModalOpen}
              sx={{
                backgroundColor: 'primary.main',
                position: 'absolute',
                width: '100%',
                height: '100%',
                borderRadius: '25%',
                opacity: 0,
                transition: 'opacity 0.5s',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                '&:hover': {
                  opacity: 0.5,
                  transition: 'opacity 0.5s',
                  cursor: 'pointer',
                },
                zIndex: 2,
              }}>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  color: 'white',
                }}>
                <Edit />
                <Typography fontFamily="Inter" fontSize={12} fontWeight={600}>
                  Edit Profile
                </Typography>
              </Box>
            </Box>

            <Avatar sx={{ width: 100, height: 100 }} />
          </Box>
          <Typography
            fontSize={18}
            fontFamily="Inter"
            fontWeight="bold"
            textAlign="center"
            width={250}>
            {user?.firstName} {user?.lastName}
          </Typography>
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            gap: 1,
          }}>
          <MenuButton
            variant="outlined"
            onClick={toggleEditUserModalOpen}
            sx={{ width: '100%' }}>
            Edit Profile
          </MenuButton>
          <MenuButton
            variant="contained"
            onClick={() => history.push('/classes')}>
            My Classes
          </MenuButton>
          <MenuButton
            variant="contained"
            startIcon={<Logout />}
            sx={{
              backgroundColor: 'error.main',
              ':hover': { backgroundColor: 'error.dark' },
            }}
            onClick={() => handleUserLogout(logout, clearUser)}>
            Log Out
          </MenuButton>
        </Box>
      </Box>
      <Box
        sx={{
          position: 'absolute',
          top: 40,
          right: 40,
          display: 'flex',
          justifyContent: 'space-between',
          gap: 2,
          alignItems: 'center',
          zIndex: 100,
        }}>
        <Box
          sx={{
            overflow: 'hidden',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            cursor: 'pointer',
            ':hover': {
              opacity: 0.5,
            },
          }}>
          {showPopUp ? (
            <Close
              sx={{ width: 30, height: 30 }}
              onClick={() => setShowPopUp(!showPopUp)}
            />
          ) : (
            <Avatar
              sx={{ width: 50, height: 50, fontSize: 18 }}
              onClick={() => setShowPopUp(!showPopUp)}
            />
          )}
        </Box>
      </Box>

      <EditUserModal
        selectedUser={user}
        open={editUserModalOpen}
        onClose={toggleEditUserModalOpen}
      />
    </Box>
  )
}

export default PopupMenu
