import React from 'react'
import { connect } from 'react-redux'
import { Box, Typography } from '@mui/material'

const DashboardPage = (props) => {
  const { adminType } = props
  return (
    <Box>
      <Typography variant="h4" textAlign="center" mb={2}>
        {adminType} Admin Dashboard
      </Typography>
      <Box sx={{ display: 'flex', flexDirection: 'column' }}></Box>
    </Box>
  )
}

const mapStateToProps = (state) => ({})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(DashboardPage)
