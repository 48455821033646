import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { useHistory } from 'react-router-dom/cjs/react-router-dom'
import {
  Grid,
  Button,
  Typography,
  Box,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
} from '@mui/material'
import moment from 'moment'

import api from 'lib/api'
import { setSnackbar } from 'reducers/ui'
import {
  setCurrentClassroomId,
  setCurrentClassroomTeacherId,
  setSelectedCourse,
} from 'reducers/user'
import InputWithLabel from 'components/InputWithLabel'
import ParagraphSection from 'components/ParagraphSection'
import UploadMaterialModal from 'components/modals/UploadMaterialModal'
import MaterialList from 'components/teacher/MaterialList'
import Insight from 'components/teacher/Insight'
import { useAuth0 } from '@auth0/auth0-react'

const CreateCourse = (props) => {
  const { userId, selectedCourse, currentSchoolId, currentClassroomTeacherId } = props
  const history = useHistory()
  const { getAccessTokenSilently } = useAuth0()

  const [loading, setLoading] = useState(false)
  const [name, setName] = useState('')
  const [description, setDescription] = useState('')
  const [grade, setGrade] = useState('')
  const [materials, setMaterials] = useState([])
  const [materialDialogOpen, setMaterialDialogOpen] = useState(false)
  const [selectedMaterial, setSelectedMaterial] = useState()

  const grades = [5, 6, 7, 8, 9, 10, 11, 12, 'University', 'Adult', 'Other']

  const toggleMaterialDialogOpen = () =>
    setMaterialDialogOpen(!materialDialogOpen)


  const handleSubmit = async () => {

    const gradeValue = typeof grade === 'number' ? `Grade ${grade}` : grade

    setLoading(true)
    try {
      const token = await getAccessTokenSilently()
      const data = {
        schoolId: currentSchoolId,
        classroomTeacherId: selectedCourse && currentClassroomTeacherId,
        name,
        description,
        grade: gradeValue,
      }

      if (!selectedCourse) {
        const classroomResponse = await api.classroom.create({
          schoolId: currentSchoolId,
          name,
          startDate: moment().format('YYYY-MM-DD hh:mm:ss'),
          endDate: moment().add(1, 'year').format('YYYY-MM-DD hh:mm:ss'),
          maxPupils: 1000,
          teachers: [{
            teachers_id: userId,
            subject: gradeValue
          }]
        }, token)

        console.log("classroomResponse: ", classroomResponse)

        const { classroom, teachers } = classroomResponse.data
        props.setCurrentClassroomId(classroom.id)
        props.setCurrentClassroomTeacherId(teachers[0].classroomTeacherId)

        data.classroomTeacherId = teachers[0].classroomTeacherId
        data.materials = materials
      }

      if (selectedCourse) data.courseId = selectedCourse.id

      let courseResponse;
      if (selectedCourse) {
        courseResponse = await api.course.update(data, token);
      } else {
        courseResponse = await api.course.create(data, token);
      }

      console.log("course response: ", courseResponse)

      const course = courseResponse.data.course
      props.setSelectedCourse(course)
      history.push(`/class/${course.id}`)
    } catch (e) {
      props.setSnackbar({ open: true, message: e.message, severity: 'error' })
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    setName(selectedCourse?.name || '')
    setDescription(selectedCourse?.description || '')
    setGrade(selectedCourse?.grade || '')
    // eslint-disable-next-line
  }, [selectedCourse])

  return (
    <Grid
      container
      sx={{ pt: 2, px: 2, mb: 4, justifyContent: 'center' }}
      spacing={4}>
      <Grid item xs={12} md={4} mb={4}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: 2,
          }}>
          <Typography fontFamily="Inter">
            Ellie works best with context — provide as much information as you
            can to guide your virtual assistant.
          </Typography>
          <Insight>
            <ParagraphSection
              title="Class Description"
              description="Include class level (grade), general course details and topics that will be covered."
            />
            <ParagraphSection
              title="Grade"
              description="Select the grade level for this class."
            />
            <ParagraphSection
              title="Course Materials"
              description="Acceptable formats include PDF, ePub, JPG, GIF, PNG and links to videos."
            />
            <Typography
              fontWeight="Bold"
              sx={{
                color: 'primary.main',
                cursor: 'pointer',
                textDecoration: 'underline',
              }}>
              View More Tips for Adding Courses
            </Typography>
          </Insight>
        </Box>
      </Grid>
      <Grid item xs={12} md={4} mb={4}>
        <Box
          sx={{
            gap: 2,
            display: 'flex',
            flexDirection: 'column',
          }}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <InputWithLabel
                label="Class Name"
                value={name}
                handleValueChange={(e) => setName(e.target.value)}
                isRequired
              />
            </Grid>
            <Grid item xs={12}>
              <InputWithLabel
                label="Class Description"
                multiline
                value={description}
                handleValueChange={(e) => setDescription(e.target.value)}
                isRequired
              />
            </Grid>
            <Grid item xs={12}>
              <FormControl fullWidth>
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '8px',
                    marginBottom: 2,
                    position: 'relative',
                  }}>
                  <Typography
                    sx={{
                      fontFamily: 'Inter',
                      fontSize: '16px',
                      fontWeight: 600,
                      lineHeight: '19.36px',
                      letterSpacing: '0.02em',
                      textAlign: 'left',
                      display: 'flex',
                      marginBottom: '8px',
                    }}>
                    Class Grade
                    <Typography
                      sx={{
                        color: 'primary.main',
                        fontSize: '12px',
                        lineHeight: '14.63px',
                        letterSpacing: '0.02em',
                        ml: 1,
                      }}>
                      *
                    </Typography>
                  </Typography>
                  <Select
                    labelId="grade-select-label"
                    value={grade}
                    onChange={(e) => setGrade(e.target.value)}
                    displayEmpty
                    renderValue={(selected) => {
                      if (selected === '') {
                        return (
                          <span
                            style={{ color: '#000000', fontStyle: 'italic' }}>
                            Select a grade
                          </span>
                        )
                      }
                      return typeof selected === 'number'
                        ? `Grade ${selected}`
                        : selected
                    }}
                    fullWidth
                    sx={{
                      fontFamily: 'Inter',
                      backgroundColor: 'white',
                      '& fieldset': { border: 'none' },
                    }}>
                    <MenuItem
                      value=""
                      sx={{
                        fontFamily: 'Inter',
                        color: '#000000',
                        fontStyle: 'italic',
                      }}>
                      Select a grade
                    </MenuItem>
                    {grades.map((grade) => (
                      <MenuItem
                        key={grade}
                        value={grade}
                        sx={{ fontFamily: 'Inter' }}>
                        {typeof grade === 'number' ? `Grade ${grade}` : grade}
                      </MenuItem>
                    ))}
                  </Select>
                </Box>
              </FormControl>
            </Grid>
          </Grid>
          <Box
            sx={{
              width: '100%',
              height: 2,
              backgroundColor: 'background.sideNav',
              mt: 2,
            }}
          />
          <Box sx={{ display: 'flex', justifyContent: 'end', gap: 2 }}>
            <Button
              variant="outlined"
              onClick={() => history.push('/classes')}
              sx={{ borderRadius: 1 }}>
              <Typography fontFamily="Inter" fontWeight="bold" fontSize={14}>
                Cancel
              </Typography>
            </Button>
            <Button
              disabled={
                loading || name === '' || description === '' || grade === ''
              }
              onClick={handleSubmit}
              color="primary"
              variant="contained"
              sx={{ borderRadius: 1 }}>
              <Typography fontFamily="Inter" fontWeight="bold" fontSize={14}>
                {selectedCourse ? 'Save' : 'Next'}
              </Typography>
            </Button>
          </Box>
        </Box>
      </Grid>
      <UploadMaterialModal
        setMaterials={setMaterials}
        selectedMaterial={selectedMaterial}
        setSelectedMaterial={setSelectedMaterial}
        courseId={selectedCourse?.id}
        open={materialDialogOpen}
        onClose={toggleMaterialDialogOpen}
      />
    </Grid>
  )
}

const mapStateToProps = (state) => ({
  userId: state.user.id,
  selectedCourse: state.user.selectedCourse,
  currentSchoolId: state.user.currentSchoolId,
  currentClassroomTeacherId: state.user.currentClassroomTeacherId,
})

const mapDispatchToProps = {
  setCurrentClassroomId,
  setCurrentClassroomTeacherId,
  setSelectedCourse,
  setSnackbar,
}

export default connect(mapStateToProps, mapDispatchToProps)(CreateCourse)
