import React, { useState } from 'react'
import { connect } from 'react-redux'
import { Box, Grid, Typography } from '@mui/material'

import { setSnackbar } from 'reducers/ui'
import StudentTable from 'components/teacher/StudentTable'
import ActivityItem from 'components/teacher/ActivityItem'

const ActivityDashboard = (props) => {
  const { selectedActivity } = props

  const [refreshing, setRefreshing] = useState(false)
  const [objectivesProgress, setObjectivesProgress] = useState([])
  const [sentimentsProgress, setSentimentsProgress] = useState([])
  const [objectivesProgressLoading, setObjectivesProgressLoading] = //* changed
    useState(false)
  const [sentimentsProgressLoading, setSentimentsProgressLoading] =
    useState(false)

  return (
    <Grid container sx={{ pt: 2, px: 2, mb: 4, justifyContent: 'center' }}>
      <Grid
        item
        xs={12}
        lg={3}
        sx={{ justifyContent: 'center', width: 400, mb: 4 }}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: 2,
            width: '100%',
            overflow: 'hidden',
            px: 2,
            pb: 2,
          }}>
          <Typography fontFamily="Inter" fontSize={22}>
            Activities
          </Typography>

          <ActivityItem
            item={selectedActivity}
            refreshing={refreshing}
            objectivesProgress={objectivesProgress}
            objectivesProgressLoading={objectivesProgressLoading}
            sentimentsProgress = {sentimentsProgress}
            sentimentsProgressLoading={sentimentsProgressLoading}
            open={true}
          />
        </Box>
      </Grid>
      <Grid item xs={12} lg={5} mb={4} sx={{ px: { xs: 2, lg: 0 } }}>
        <StudentTable
          refreshing={refreshing}
          setRefreshing={setRefreshing}
          setObjectivesProgress={setObjectivesProgress}
          setObjectivesProgressLoading={setObjectivesProgressLoading}
          setSentimentsProgress={setSentimentsProgress}
          setSentimentsProgressLoading={setSentimentsProgressLoading}
        />
      </Grid>
    </Grid>
  )
}

const mapStateToProps = (state) => ({
  selectedActivity: state.user.selectedActivity,
})

const mapDispatchToProps = {
  setSnackbar,
}

export default connect(mapStateToProps, mapDispatchToProps)(ActivityDashboard)
