import React from 'react'
import { connect } from 'react-redux'
import { useHistory } from 'react-router-dom/cjs/react-router-dom'
import { Box, Grid, Typography, useMediaQuery } from '@mui/material'
import { useAuth0 } from '@auth0/auth0-react'

import { clearUser } from 'reducers/user'
import AppLogo from 'components/AppLogo'
import PopupMenu from 'components/PopupMenu'
import { ReactComponent as GearIcon } from '../assets/gear.svg'
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min'
import { useWindowWidth } from 'hooks/useWindowWidth'

const TopBar = ({ user }) => {
  return (
    <Box
      sx={{
        position: 'absolute',
        display: 'flex',
        alignContents: 'center',
        justifyContent: 'center',
        top: 0,
        right: 0,
      }}>
      <PopupMenu user={user} />
    </Box>
  )
}

const User = (props) => {
  const { user, clearUser } = props
  const { logout } = useAuth0()
  const location = useLocation()
  const history = useHistory()
  const isDesktop = useMediaQuery((theme) => theme.breakpoints.up('md'))
  const width = useWindowWidth()

  return (
    <Box>
      <Grid maxWidth container>
        <Grid item xs={2}>
          <Box
            onClick={() => history.push('/')}
            sx={{
              background:
                'linear-gradient(110.12deg, #0959F3 44.43%, #0345C4 99.4%)',
              height: 80,
              display: 'flex',
              justifyContent: 'center',
              borderRadius: '0 0 8px 0',
              '&:hover': {
                opacity: 0.5,
              },
              cursor: 'pointer',
              maxWidth: 200,
            }}>
            <AppLogo isDesktop={isDesktop} showMargin={false} />
          </Box>
        </Grid>
        <Grid item xs={10} pl={4} display="flex">
          {user.role === 'pupil' && location.pathname.startsWith('/chat') && (
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
              <Typography
                onClick={() => history.push('/classes')}
                sx={{
                  fontFamily: 'Inter',
                  fontWeight: 500,
                  fontSize: 18,
                  color: 'primary.main',
                  textDecoration: 'underline',
                  cursor: 'pointer',
                }}>
                Classes
              </Typography>
              <Typography
                sx={{
                  fontFamily: 'Inter',
                  fontWeight: 400,
                  fontSize: 18,
                }}>
                /
              </Typography>
              <Typography
                sx={{
                  fontFamily: 'Inter',
                  fontWeight: 400,
                  fontSize: 18,
                }}>
                {user?.selectedCourse?.name}
              </Typography>
              <Typography
                sx={{
                  fontFamily: 'Inter',
                  fontWeight: 400,
                  fontSize: 18,
                }}>
                /
              </Typography>
              <Typography
                sx={{
                  fontFamily: 'Inter',
                  fontWeight: 400,
                  fontSize: 18,
                }}>
                {user?.selectedActivity?.activity?.name}
              </Typography>
            </Box>
          )}
          {user.role === 'teacher' &&
            location.pathname.startsWith('/class/') && (
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                <Typography
                  onClick={() => history.push('/classes')}
                  sx={{
                    fontFamily: 'Inter',
                    fontWeight: 500,
                    fontSize: 18,
                    color: 'primary.main',
                    textDecoration: 'underline',
                    cursor: 'pointer',
                  }}>
                  {!!location.pathname.split('/')[3] && width < 800
                    ? '..'
                    : 'Classes'}
                </Typography>
                <Typography
                  sx={{
                    fontFamily: 'Inter',
                    fontWeight: 400,
                    fontSize: 18,
                  }}>
                  /
                </Typography>
                <Typography
                  onClick={() => {
                    if (!!location.pathname.split('/')[3])
                      history.push(
                        location.pathname.split('/').slice(0, 3).join('/')
                      )
                  }}
                  sx={{
                    fontFamily: 'Inter',
                    fontWeight: !!location.pathname.split('/')[3] ? 500 : 700,
                    fontSize: 18,
                    whiteSpace: 'nowrap',
                    textDecoration:
                      !!location.pathname.split('/')[3] && 'underline',
                    color: !!location.pathname.split('/')[3] && 'primary.main',
                    cursor: !!location.pathname.split('/')[3] && 'pointer',
                  }}>
                  {user?.selectedCourse
                    ? user.selectedCourse.name
                    : 'Create Class'}
                </Typography>
                {user?.selectedCourse && !location.pathname.split('/')[3] && (
                  <Box
                    onClick={() =>
                      history.push(
                        `${location.pathname
                          .split('/')
                          .slice(0, 3)
                          .join('/')}/edit`
                      )
                    }
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      ':hover': { cursor: 'pointer', opacity: 0.5 },
                    }}>
                    <GearIcon />
                  </Box>
                )}
                {!!location.pathname.split('/')[3] && (
                  <>
                    <Typography
                      sx={{
                        fontFamily: 'Inter',
                        fontWeight: 400,
                        fontSize: 18,
                      }}>
                      /
                    </Typography>
                    {location.pathname.split('/')[3] === 'activity' &&
                    location.pathname.split('/')[4] === 'add' ? (
                      <Typography
                        sx={{
                          fontFamily: 'Inter',
                          fontWeight: 700,
                          fontSize: 18,
                          whiteSpace: 'nowrap',
                        }}>
                        Add Activity
                      </Typography>
                    ) : location.pathname.split('/')[3] === 'activity' &&
                      location.pathname.split('/')[5] === 'edit' ? (
                      <>
                        <Typography
                          onClick={() =>
                            history.push(
                              location.pathname.split('/').slice(0, 5).join('/')
                            )
                          }
                          sx={{
                            fontFamily: 'Inter',
                            fontWeight: !!location.pathname.split('/')[3]
                              ? 500
                              : 700,
                            fontSize: 18,
                            whiteSpace: 'nowrap',
                            textDecoration:
                              !!location.pathname.split('/')[3] && 'underline',
                            color:
                              !!location.pathname.split('/')[3] &&
                              'primary.main',
                            cursor:
                              !!location.pathname.split('/')[3] && 'pointer',
                          }}>
                          {user?.selectedActivity?.activity?.name}
                        </Typography>
                        <Typography
                          sx={{
                            fontFamily: 'Inter',
                            fontWeight: 400,
                            fontSize: 18,
                          }}>
                          /
                        </Typography>
                        <Typography
                          sx={{
                            fontFamily: 'Inter',
                            fontWeight: 700,
                            fontSize: 18,
                            whiteSpace: 'nowrap',
                          }}>
                          Edit Activity
                        </Typography>
                      </>
                    ) : (
                      location.pathname.split('/')[3] === 'activity' && (
                        <>
                          <Typography
                            sx={{
                              fontFamily: 'Inter',
                              fontWeight: 700,
                              fontSize: 18,
                              whiteSpace: 'nowrap',
                            }}>
                            Activity Dashboard
                          </Typography>
                          <Box
                            onClick={() =>
                              history.push(
                                `${location.pathname
                                  .split('/')
                                  .slice(0, 5)
                                  .join('/')}/edit`
                              )
                            }
                            sx={{
                              display: 'flex',
                              justifyContent: 'center',
                              alignItems: 'center',
                              ':hover': { cursor: 'pointer', opacity: 0.5 },
                            }}>
                            <GearIcon />
                          </Box>
                        </>
                      )
                    )}
                    {location.pathname.split('/')[3] === 'edit' && (
                      <Typography
                        sx={{
                          fontFamily: 'Inter',
                          fontWeight: 700,
                          fontSize: 18,
                          whiteSpace: 'nowrap',
                        }}>
                        Edit Class
                      </Typography>
                    )}
                  </>
                )}
              </Box>
            )}
        </Grid>
        <TopBar
          user={user}
        />
      </Grid>

      {props.children}
    </Box>
  )
}

const mapStateToProps = (state) => ({
  user: state.user,
})

const mapDispatchToProps = {
  clearUser,
}

export default connect(mapStateToProps, mapDispatchToProps)(User)
