import React, { useEffect, useState } from 'react'
import { Box, Typography } from '@mui/material'

const ObjectiveWithProgress = ({ loading, progress, description }) => {
  const [localProgress, setLocalProgress] = useState(progress)

  useEffect(() => {
    if (!loading) setLocalProgress(progress)
  }, [loading, progress])

  return (
    <Box
      sx={{
        borderRadius: 2,
        overflow: 'hidden',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        backgroundColor: 'primary.weak',
        width: '90%',
        alignSelf: 'center',
        my: 1,
        pt: 4,
      }}>
      <Typography
        fontFamily="Inter"
        fontSize={14}
        sx={{ paddingLeft: 4, paddingRight: 4, marginBottom: 4 }}>
        {description}
      </Typography>
      <Box
        sx={{
          width: '100%',
          height: 10,
          backgroundColor: 'inputs.main',
        }}>
        <Box
          sx={{
            width: `${localProgress}%`,
            height: 10,
            backgroundColor: 'primary.main',
          }}
        />
      </Box>
    </Box>
  )
}

export default ObjectiveWithProgress
