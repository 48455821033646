import React from 'react'
import { Box, InputAdornment, TextField, Typography } from '@mui/material'
import { Search } from '@mui/icons-material'

import ActivityItem from './ActivityItem'

const ActivitiesList = ({ activities, memo, setMemo = {}, chatSummaries }) => {
  if (!activities) {
    return (
      <Typography variant="h6" fontFamily="Inter">
        No activities found
      </Typography>
    )
  }

  return (
    <>
      <Box sx={{ width: '100%' }}>
        <Typography fontFamily="Inter" fontSize={16} fontWeight={600}>
          Class History
        </Typography>
        <TextField
          variant="outlined"
          margin="normal"
          fullWidth
          id="search"
          fontFamily="Inter"
          name="method"
          autoComplete="name"
          autoFocus
          value={memo}
          onChange={(e) => setMemo(e.target.value)}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <Search />
              </InputAdornment>
            ),
            sx: {
              backgroundColor: 'background.paper',
            },
          }}
        />
      </Box>
      <Box
        sx={{
          width: '100%',
          mt: 2,
          height: '74vh',
          overflowY: 'scroll',
          display: 'flex',
          flexDirection: 'column',
          borderRadius: 2,
          gap: 1,
        }}>
        {activities.map((activity, index) => (
          <ActivityItem
            key={index}
            activity={activity}
            summary={activity?.activity?.summary}
          />
        ))}
      </Box>
    </>
  )
}

export default ActivitiesList
